import React, { Component } from 'react';

import Helmet from "react-helmet";

import './Loader.scss';

export default class Loader extends Component {
    render() {
        return (
            <>
            <Helmet>
            <body className="no-scroll" />
            </Helmet>

            <div className='cart-checkout'>
            <div className='cart-checkout-wrap'>
                <div className='loader'></div>
                <div className='wait'>Loading... Please wait</div>
            </div>
            </div> 
        </>
        )
    }
}