import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cartActions from "../../actions/cartActions";
import CartReview from "../../components/common/CartReview/CartReview.jsx";

const mapStateToProps = (state) => ({
  cartProducts: state.cart.cartProducts,
  interPrice: state.interPrice.interPrice
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(cartActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CartReview);
