const initialState = {
    interPrice: null,
    region: null
    };
    
    export default (state = initialState, action) => {
      switch (action.type) {
        case "SET_INTER_PRICE":
          return {
            ...state,
            interPrice: action.payload,
          };
        case "SET_REGION":
          return {
            ...state,
            region: action.payload,
          }
        default:
          return state;
      }
    };