import React, { Component } from "react";
import ReactGA from 'react-ga';
import './CartIcon.scss';

class CartIcon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false
    }

    this.onClick = this.onClick.bind(this);
  }

  onAnalyticsSend(value) {
    ReactGA.event({
      category: "StoreComponent",
      action: "Header",
      label: value
    }, ['gadev']);
  }

  onClick() {
    this.props.cartMenuShow();
  }

  render() {

    const { cartProducts } = this.props;

    let cnt = 0;
    if (typeof cartProducts !== "undefined") {
      cartProducts.forEach(cartProduct => {
        let count = cartProduct.count ? cartProduct.count : 1
        count = count > 0 ? count : 1; 

        cnt += count;
      });
    } 

    return (
      <>
        <div className="cart-icon" onClick={() => {this.onClick(); this.onAnalyticsSend('Cart_icon_click')}}>
          { (cnt > 0) ? <div className="count">{cnt}</div> : null }
        </div>
      </>
    );
  }
}

export default CartIcon;
