import React, { Component } from "react";
import ReactGA from 'react-ga';
import './ContactChat.scss';

class ContactChat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chatEnabled: false
    }
  }

  onAnalyticsSend(value) {
    ReactGA.event({
      category: "StoreComponent",
      action: "Header",
      label: value
    }, ['gadev']);
  }

  componentDidMount() {
    /* Add this class to any elements you want to use to open Drift.
     *
     * Examples:
     * - <a className="drift-open-chat">Questions? We're here to help!</a>
     * - <button className="drift-open-chat">Chat now!</button>
     *
     * You can have any additional classes on those elements that you
     * would ilke.
     */
    var DRIFT_CHAT_SELECTOR = '.drift-open-chat';

    /* http://youmightnotneedjquery.com/#ready */
    function ready(fn) {
      if (document.readyState != 'loading') {
        fn();
      } else if (document.addEventListener) {
        document.addEventListener('DOMContentLoaded', fn);
      } else {
        document.attachEvent('onreadystatechange', function() {
          if (document.readyState != 'loading')
            fn();
        });
      }
    }

    /* http://youmightnotneedjquery.com/#each */
    function forEachElement(selector, fn) {
      var elements = document.querySelectorAll(selector);
      for (var i = 0; i < elements.length; i++)
        fn(elements[i], i);
    }

    function openSidebar(driftApi, event) {
      event.preventDefault();
      driftApi.sidebar.open();
      return false;
    }

    var self = this;
    ready(function() {
      if (typeof drift !== "undefined") {
        drift.on('ready', function(api) {
          var handleClick = openSidebar.bind(this, api)
          forEachElement(DRIFT_CHAT_SELECTOR, function(el) {
            el.addEventListener('click', handleClick);
          });

          self.setState({
            chatEnabled: true
          });
        });
      }
    });
  }


  render() {
    const { chatEnabled } = this.state;

    return (
      <div className="contact-chat"> 
        {chatEnabled ?
          <div className="contact-chat-btn drift-open-chat">Chat now</div>
          :
          <a className="contact-chat-link" onClick={() => this.onAnalyticsSend('ContactUs_click')} href="https://smartbear.com/contact-us/">Contact us</a>        
        }
      </div>
    );
  }
}

export default ContactChat;
