import React, { useRef } from 'react';

const footer = () => {
    const linksRef = useRef(null);

    function handleClick() {
        linksRef.current.classList.toggle('active');
    }

    return (
        <footer>
            <nav className='main-footer'>
                <div className='container'>
                    <div className='row'>
                        <div className='product-column col-xl-9'>
                            <div className='product-column-category text-lg-left text-center'>
                                <p className='product-column-category display-5 text-white d-inline cursor-hover-md-pointer'>
                                    Explore SmartBear Products
                                </p>
                                <i
                                    onClick={handleClick}
                                    className='d-inline d-lg-none ml-2 fal fa-plus-square cursor-hover-md-pointer text-white'
                                ></i>
                            </div>
                            <div className='ab-orange-sm ml-lg-0 mr-lg-auto mx-auto w--60'></div>
                            <div
                                ref={linksRef}
                                className='product-column-content mb-3'
                                id='menu-1'
                            >
                                <div className='d-flex flex-wrap col-12'>
                                    <ul className='col-md-3 col-sm-6 mb-0 order-1'>
                                        <li>
                                            <a href='/product/alertsite/overview/'>
                                                <span className='prod-icon as inverse sm'></span>
                                                AlertSite
                                            </a>
                                        </li>
                                        <li>
                                            <a href='/product/aqtime-pro/overview/'>
                                                <span className='prod-icon aqt inverse sm'></span>
                                                AQTime Pro
                                            </a>
                                        </li>
                                        <li>
                                            <a href='/product/bitbar/'>
                                                <span className='prod-icon bb inverse sm'></span>
                                                BitBar
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className=' footer-external'
                                                href='https://bugsnag.com/?utm_medium=referral&amp;utm_source=smartbear.com&amp;utm_campaign=footer'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                <span className='prod-icon bs inverse sm'></span>
                                                BugSnag
                                            </a>
                                        </li>
                                        <li>
                                            <a href='/product/capture-for-jira/'>
                                                <span className='prod-icon ca inverse sm'></span>
                                                Capture for Jira
                                            </a>
                                        </li>
                                        <li>
                                            <a href='/product/collaborator/overview/'>
                                                <span className='prod-icon co inverse sm'></span>
                                                Collaborator
                                            </a>
                                        </li>
                                    </ul>
                                    <ul className='col-md-3 col-sm-6 mb-0 order-md-2 order-sm-3 order-2'>
                                        <li>
                                            <a
                                                className=' footer-external'
                                                href='https://marketplace.atlassian.com/apps/1221264/cucumber-for-jira/?utm_medium=referral&amp;utm_source=smartbear.com&amp;utm_campaign=footer'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                <span className='prod-icon c4j inverse sm'></span>
                                                Cucumber for Jira
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className=' footer-external'
                                                href='https://cucumber.io/tools/cucumber-open/?utm_medium=referral&amp;utm_source=smartbear.com&amp;utm_campaign=footer'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                <span className='prod-icon cu inverse sm'></span>
                                                Cucumber Open
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className=' footer-external'
                                                href='https://loadninja.com/?utm_medium=referral&amp;utm_source=smartbear.com&amp;utm_campaign=footer'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                <span className='prod-icon ln inverse sm'></span>
                                                LoadNinja
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className=' footer-external'
                                                href='https://pact.io/?utm_medium=referral&amp;utm_source=smartbear.com&amp;utm_campaign=footer'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                <span className='prod-icon po inverse sm'></span>
                                                Pact
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className='footer-external'
                                                href='https://pactflow.io/?utm_medium=referral&amp;utm_source=smartbear.com&amp;utm_campaign=footer'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                <span className='prod-icon pf inverse sm'></span>
                                                PactFlow
                                            </a>
                                        </li>
                                        <li>
                                            <a href='/product/ready-api/overview/'>
                                                <span className='prod-icon ra inverse sm'></span>
                                                ReadyAPI
                                            </a>
                                        </li>
                                    </ul>
                                    <ul className='col-md-3 col-sm-6 mb-0 order-md-3 order-sm-2 order-3'>
                                        <li>
                                            <a
                                                className=' footer-external'
                                                href='https://soapui.org?utm_medium=referral&amp;utm_source=smartbear.com&amp;utm_campaign=footer'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                <span className='prod-icon su inverse sm'></span>
                                                SoapUI
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className=' footer-external'
                                                href='https://swagger.io/tools/open-source/?utm_medium=referral&amp;utm_source=smartbear.com&amp;utm_campaign=footer'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                <span className='prod-icon sw inverse sm'></span>
                                                Swagger
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className=' footer-external'
                                                href='https://swagger.io/tools/swaggerhub/?utm_medium=referral&amp;utm_source=smartbear.com&amp;utm_campaign=footer'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                <span className='prod-icon swh inverse sm'></span>
                                                SwaggerHub
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className=' footer-external'
                                                href='https://swagger.io/tools/swaggerhub-explore/?utm_medium=referral&amp;utm_source=smartbear.com&amp;utm_campaign=footer'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                <span className='prod-icon swh inverse sm'></span>
                                                SwaggerHub Explore
                                            </a>
                                        </li>
                                        <li>
                                            <a href='/product/testcomplete/overview/'>
                                                <span className='prod-icon tc inverse sm'></span>
                                                TestComplete
                                            </a>
                                        </li>
                                        <li>
                                            <a href='/product/ready-api/testengine/overview/'>
                                                <span className='prod-icon ten inverse sm'></span>
                                                TestEngine
                                            </a>
                                        </li>
                                    </ul>
                                    <ul className='col-md-3 col-sm-6 mb-0 order-4'>
                                        <li>
                                            <a href='/product/testleft/overview/'>
                                                <span className='prod-icon tl inverse sm'></span>
                                                TestLeft
                                            </a>
                                        </li>
                                        <li>
                                            <a href='/product/visualtest/'>
                                                <span className='prod-icon vt inverse sm'></span>
                                                VisualTest
                                            </a>
                                        </li>
                                        <li>
                                            <a href='/test-management/zephyr/'>
                                                <span className='prod-icon ze inverse sm'></span>
                                                Zephyr
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-3 col-12 border-lg-d-none border-left-1 border-left-gray'>
                            <ul className='d-flex flex-xl-column align-items-xl-end justify-content-center flex-wrap txt-clr-neutral-1 px-0' style={{fontSize: '1rem'}}>
                                <li className='my-xl-1'>
                                    <a
                                        className='text-orange'
                                        href='/company/about-us/'
                                    >
                                        About Us
                                    </a>
                                </li>
                                <span className='d-xl-none vertical-line px-2'>
                                    {' '}
                                    |{' '}
                                </span>
                                <li className='my-xl-1'>
                                    <a
                                        className='text-orange'
                                        href='/company/careers/'
                                    >
                                        Careers
                                    </a>
                                </li>
                                <span className='d-xl-none vertical-line px-2'>
                                    {' '}
                                    |{' '}
                                </span>
                                <li className='my-xl-1'>
                                    <a
                                        className='text-orange'
                                        href='/solutions/'
                                    >
                                        Solutions
                                    </a>
                                </li>
                                <span className='d-xl-none vertical-line px-2'>
                                    {' '}
                                    |{' '}
                                </span>
                                <li className='my-xl-1'>
                                    <a
                                        className='text-orange'
                                        href='/partners/'
                                    >
                                        Partners
                                    </a>
                                </li>
                                <span className='d-xl-none vertical-line px-2'>
                                    {' '}
                                    |{' '}
                                </span>
                                <li className='my-xl-1'>
                                    <a
                                        className='text-orange'
                                        href='/company/responsibility/'
                                    >
                                        Responsibility
                                    </a>
                                </li>
                            </ul>
                            <hr className='d-xl-block d-none border-top-1 border-top-gray w--30 my-4 ml-auto mr-0' />
                            <ul className='col-12 txt-clr-neutral-1 px-0 text-center' style={{fontSize: '1rem'}}>
                                <li className='py-1 text-xl-right text-center px-sm-0 d-xl-block d-block d-sm-inline-block font-roboto'>
                                    <a
                                        href='/contact-us/'
                                        className='text-orange'
                                    >
                                        Contact Us{' '}
                                        <span>
                                            <svg
                                                style={{ width: '19px' }}
                                                x='0px'
                                                y='0px'
                                                viewBox='0 0 24.773 20.137'
                                            >
                                                <g>
                                                    <path
                                                        style={{
                                                            fill: 'none',
                                                            stroke: '#758491',
                                                            strokeWidth: 2,
                                                            strokeMiterlimit: 10,
                                                        }}
                                                        d='M20.346,19.137H4.428C2.543,19.137,1,17.595,1,15.709V4.428C1,2.543,2.543,1,4.428,1h15.918 c1.885,0,3.428,1.543,3.428,3.428v11.282C23.773,17.595,22.231,19.137,20.346,19.137z'
                                                    ></path>
                                                    <polyline
                                                        style={{
                                                            fill: 'none',
                                                            stroke: '#758491',
                                                            strokeWidth: 2,
                                                            strokeMiterlimit: 10,
                                                        }}
                                                        points='1.076,3.862 12.305,12.064 23.7,3.697 	'
                                                    ></polyline>
                                                    <line
                                                        style={{
                                                            fill: 'none',
                                                            stroke: '#758491',
                                                            strokeWidth: 2,
                                                            strokeMiterlimit: 10,
                                                        }}
                                                        x1='23.535'
                                                        y1='16.248'
                                                        x2='15.498'
                                                        y2='10.358'
                                                    ></line>
                                                    <line
                                                        style={{
                                                            fill: 'none',
                                                            stroke: '#758491',
                                                            strokeWidth: 2,
                                                            strokeMiterlimit: 10,
                                                        }}
                                                        x1='1.076'
                                                        y1='16.303'
                                                        x2='8.948'
                                                        y2='10.358'
                                                    ></line>
                                                </g>
                                            </svg>
                                        </span>
                                    </a>
                                </li>
                                <span className='d-xl-none d-sm-inline-block d-none vertical-line px-2'>
                                    {' '}
                                    |{' '}
                                </span>
                                <li className='py-1 text-xl-right text-center px-sm-0 d-xl-block d-block d-sm-inline-block font-roboto'>
                                    +1 617-684-2600{' '}
                                    <span className='txt-clr-neutral-10'>
                                        USA
                                    </span>
                                </li>
                                <span className='d-xl-none d-sm-inline-block d-none vertical-line px-2'>
                                    {' '}
                                    |{' '}
                                </span>
                                <li className='py-1 text-xl-right text-center px-sm-0 d-xl-block d-block d-sm-inline-block font-roboto'>
                                    +353 91 398300{' '}
                                    <span className='txt-clr-neutral-10'>
                                        EUR
                                    </span>
                                </li>
                                <span className='d-xl-none d-sm-inline-block d-none vertical-line px-2'>
                                    {' '}
                                    |{' '}
                                </span>
                                <li className='py-1 text-xl-right text-center px-sm-0 d-xl-block d-block d-sm-inline-block font-roboto'>
                                    +61 391929960{' '}
                                    <span className='txt-clr-neutral-10'>
                                        AUS
                                    </span>
                                </li>
                            </ul>
                            <div className="gearbear">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.141 28.719">
                                        <path id="Path_111" data-name="Path 111" style={{ fill: '#d2e1e8', opacity: .15 }} d="M29.591,24.318H21.549l-1.442,4.4H31Zm21.549,4.4L46.929,15.744,49.66,7.36,39.531,0,32.4,5.2H18.741L11.609,0,1.48,7.36l2.732,8.384L0,28.719H13.316l-1.024-3.187,13.316-9.674,13.316,9.674L37.9,28.719Z"></path>
                                    </svg>

                                </div>
                        </div>
                    </div>
                </div>
            </nav>
            <nav className='bottom-footer'>
                <div className='container'>
                    <div className='row'>
                        <div className='bottom-links-container col-md-8' style={{fontSize: '1rem'}}>
                            <span className='copyright'>
                                © 2024 SmartBear Software. All Rights Reserved.
                            </span>
                            <br />
                            <div className='bottom-links col-md-12'>
                                <ul>
                                    <li>
                                        <a href='/privacy/'>Privacy</a>
                                    </li>
                                    <span className='vertical-line'> | </span>
                                    <li>
                                        <a href='/terms-of-use/'>
                                            Terms of Use
                                        </a>
                                    </li>
                                    <span className='vertical-line'> | </span>
                                    <li>
                                        <a href='/site-map/'>Site Map</a>
                                    </li>
                                    <span className='vertical-line'> | </span>
                                    <li>
                                        <a href='/website-terms-of-use/'>
                                            Website Terms of Use
                                        </a>
                                    </li>
                                    <span className='vertical-line'> | </span>
                                    <li>
                                        <a href='/security/'>Security</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='social-links-container col-md-4'>
                            <a
                                href='http://twitter.com/smartbear'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='social-link'
                            >
                                <i className='fab fa-twitter-square'></i>
                            </a>
                            <a
                                href='http://www.facebook.com/smartbear'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='social-link'
                            >
                                <i className='fab fa-facebook-square'></i>
                            </a>
                            <a
                                href='https://www.linkedin.com/company/smartbear/'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='social-link'
                            >
                                <i className='fab fa-linkedin'></i>
                            </a>
                            <a
                                href='http://www.youtube.com/user/SmartBearSoftware'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='social-link'
                            >
                                <i className='fab fa-youtube'></i>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </footer>
    );
};

export default footer;
