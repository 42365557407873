import React, { Component } from "react";

import ProductsStore from '../../data/products.js';
import ProductTemplate from '../templates/ProductTemplate.jsx';

import SaleItemRadio from '../../components/common/SaleItemRadio/SaleItemRadio.jsx';
import AddonList from '../../components/common/AddonList/AddonList.jsx';

import queryString from "query-string";
import { withRouter } from "react-router-dom";

class ReadyAPIPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      licenseType: null,
      moduleErrorMessage: false,
      addonIds: [],
      saleitemsIds: [],
      testSaleitemId: null,
      perfSaleitemId: null,
      virtSaleitemId: null
    }
 
    this.handleLicenseChange = this.handleLicenseChange.bind(this);    
    this.handleAddonChange = this.handleAddonChange.bind(this); 
    this.handleSalesItemsChange = this.handleSalesItemsChange.bind(this);
    this.handleClearSelection = this.handleClearSelection.bind(this);

    this.product = ProductsStore.getProductByUrl('readyapi');
  }

  updateSelection() {

    let { testSaleitemId, perfSaleitemId, virtSaleitemId } = this.state;

    let searchObj = queryString.parse(window.location.search);

    let licenseType=null,
        addonIds=[],
        saleitemsIds=[];

    if (searchObj.licenseType) {
      switch(searchObj.licenseType) {
        case 'floating':
        case 'nodeLocked':
          licenseType = searchObj.licenseType;
      }    
    }

    if (searchObj.addons) {
      addonIds = searchObj.addons.split(",");
    }

    if (searchObj.modules) {
      saleitemsIds = searchObj.modules.split(',');
   
      for (let i = 0; i < saleitemsIds.length; i++) {
        let saleitemId = saleitemsIds[i];

        if (saleitemId.includes('test')) {
          testSaleitemId = saleitemId;
        } else if (saleitemId.includes('performance')) {
          perfSaleitemId = saleitemId;
        } else if (saleitemId.includes('virtualization')) {
          virtSaleitemId = saleitemId;
        }
      }
    }

    this.setState({
      licenseType: licenseType,
      addonIds: addonIds,
      saleitemsIds: saleitemsIds,
      perfSaleitemId: perfSaleitemId,
      testSaleitemId: testSaleitemId,
      virtSaleitemId: virtSaleitemId      
    }, () => this.updateCart());
  }

  componentDidMount(){
    this.updateSelection();
  }

  handleLicenseChange(e) {
    this.setState({
      licenseType: e.target.value,
      testSaleitemId: null,
      perfSaleitemId: null,
      virtSaleitemId: null,
      saleitemsIds: []
    }, () => this.updateCart());    
  }

  handleClearSelection (name) {
    this.handleSalesItemsChange(name, null);
  }

  handleSalesItemsChange(name, value, checked) {

    let { testSaleitemId, perfSaleitemId, virtSaleitemId } = this.state;

    switch(name) {
      case 'performance':
        perfSaleitemId = value;
        break;

      case 'test':
        testSaleitemId = value;
        break;

      case 'virtualization':
        virtSaleitemId = value;
        break;
    }

    let saleitemsIds = [];
    if (perfSaleitemId) saleitemsIds.push(perfSaleitemId);
    if (testSaleitemId) saleitemsIds.push(testSaleitemId);
    if (virtSaleitemId) saleitemsIds.push(virtSaleitemId);
    
    this.setState({ 
      saleitemsIds: saleitemsIds,
      perfSaleitemId: perfSaleitemId,
      testSaleitemId: testSaleitemId,
      virtSaleitemId: virtSaleitemId
    }, () => this.updateCart());

  }

  handleAddonChange(name, value, checked) {

    let addonIds = [...this.state.addonIds ];

    let exists = false;

    addonIds.forEach((item, idx) => {
      if (item === name) {
        exists = true;
      }
    });

    if (checked && !exists) {
      addonIds.push(name);
    } else if (!checked && exists) {
      addonIds = addonIds.filter(item => item !== name);      
    }

    this.setState({ 
      addonIds: addonIds 
    }, () => this.updateCart());
  }

  updateCart() {
    const { licenseType, addonIds, saleitemsIds } = this.state;

    let previewSaleItems = [];
    let queryParams = [];
    let selectedAddons = [];
    let selectedModules = [];

    switch(licenseType) {
      case 'floating':
        queryParams.push("licenseType=" + licenseType);
        break;

      case 'nodeLocked':
        queryParams.push("licenseType=" + licenseType);
        break;       
    }

    addonIds.forEach(item => {
      let addon = ProductsStore.getSaleItemById(item);
      if (addon) {
        previewSaleItems.push(addon);
        selectedAddons.push(addon.id);
      }
    });

    saleitemsIds.forEach(id => {
      let saleItem = ProductsStore.getSaleItemById(id);
      if (saleItem) {
        previewSaleItems.push(saleItem); 
        selectedModules.push(saleItem.id)      
      }
    });

    if (selectedAddons.length) {
      queryParams.push("addons=" + selectedAddons.join(","));
    }

    if (selectedModules.length) {
      queryParams.push("modules=" + selectedModules.join(","));
    }

    this.props.cartPreviewAddProducts(previewSaleItems);

    if (queryParams.length) {
      let url = window.location.pathname.replace(process.env.PUBLIC_URL, "");
      this.props.history.push(url + '?' + queryParams.join("&"));
    }
  }

  onClean() {
    this.setState({licenseType: null,
    moduleErrorMessage: false,
    addonIds: [],
    saleitemsIds: [],
  }, () => this.updateCart())
  let pathname = window.location.pathname.split(3)[3]
    this.props.history.push(pathname)
  }

  render() {
    const { licenseType, addonIds } = this.state;
    const { testSaleitemId, perfSaleitemId, virtSaleitemId } = this.state;
    
    let disabled = true;
    let selectedLicenceType = 'nodeLocked';
    if (licenseType !== null) {
      selectedLicenceType = licenseType;
      disabled = false;
    }

    return (
      <ProductTemplate product={this.product}>
      <div className="page-license">
      <div className="page-license-option mb-4">
          <div className="page-license-option-title">License type
            <div className="page-license-option-info" data-html={true} data-tip="<b>Fixed:</b> Single user test on physical machines only<br />
  <b>Floating:</b> Multiple users test on physical and virtual machines"></div>
          </div>
          <input type="radio" 
            id="licenseNodeLocked" 
            name="license" 
            value="nodeLocked" 
            checked={licenseType === "nodeLocked"}
            onChange={this.handleLicenseChange} />
          <label htmlFor="licenseNodeLocked">Fixed</label>

          <input type="radio" 
            id="licenseFloat" 
            name="license" 
            value="floating" 
            checked={licenseType === "floating"}
            onChange={this.handleLicenseChange} />
          <label htmlFor="licenseFloat">Floating</label>
        </div>
        
        <div className={`page-license-option-title my-3`}>Select modules</div>
        <div className={`page-license-option`}>
          <div className={`page-license-option-title my-3`}>API Functional & Security Testing:
            {testSaleitemId ? <span className="page-license-option-clear" onClick={e => this.handleClearSelection('test')}>Clear</span> : null}
          </div>

          <SaleItemRadio 
            name="test" 
            titleRemove="ReadyAPI Test - "
            items={this.product.saleitems.test[selectedLicenceType]} 
            disabled={disabled}
            selectedId={testSaleitemId}
            onClick={this.handleSalesItemsChange} />

          <div className={`page-license-option-title my-3`}>API Performance Testing:
            {perfSaleitemId ? <span className="page-license-option-clear" onClick={e => this.handleClearSelection('performance')}>Clear</span> : null}
          </div>

          <SaleItemRadio 
            name="performance" 
            titleRemove="ReadyAPI Performance - "
            items={this.product.saleitems.performance[selectedLicenceType]} 
            disabled={disabled}
            selectedId={perfSaleitemId}
            onClick={this.handleSalesItemsChange} />
        
          <div className={`page-license-option-title my-3`}>API & Service Virtualization:
            {virtSaleitemId ? <span className="page-license-option-clear" onClick={e => this.handleClearSelection('virtualization')}>Clear</span> : null}
          </div>

          <SaleItemRadio 
            name="virtualization" 
            titleRemove="ReadyAPI Virtualization - "
            items={this.product.saleitems.virtualization[selectedLicenceType]} 
            disabled={disabled}
            selectedId={virtSaleitemId}
            onClick={this.handleSalesItemsChange} />            

        </div>
      </div>  
      </ProductTemplate>
    );
  }
}

export default withRouter(ReadyAPIPage);
