import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as productActions from "../../actions/productActions";
import DefaultTemplate from '../../pages/templates/DefaultTemplate.jsx';

const mapStateToProps = (state) => ({
    InterPrice: state.interPrice.interPrice,
    isReady: state.products.isReady,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(productActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultTemplate);
