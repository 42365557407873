import icon from '../assets/Collaborator-Logo.svg';

const CollaboratorConfig = {
    name: "Collaborator",
    url: "collaborator",
    icoClassName: "co",
    ico: icon,
    saleitems: {
        floating: [{
            id: "collaborator-one-year-maintenance-floating",
            title: "Collaborator Enterprise - 1 Year Maintenance",
            licenseType: "Floating License",
            description: "Unlimited number of users based on licenses owned. Subscription - you may install and use the Software solely as permitted by the license type purchased on compatible devices during the applicable Subscription Term.",
            price: "{~interprice|CS0~}",
            url: ""
        }, {
            id: "collaborator-subscription-floating",
            title: "Collaborator Enterprise - Subscription",
            licenseType: "Floating License",
            description: "Unlimited number of users based on licenses owned. You may install and use the Software as permitted by the license type purchased on compatible devices without any end date.",
            price: "{~interprice|CS0~}",
            url: ""
        }],
        nodeLocked: [{
            id: "collaborator-five-user-one-year-fixed",
            title: "Collaborator Team - 5 Users - 1 Year Subscription",
            licenseType: "Fixed License",
            description: "Up to 50 Users totally. Purchase up to 10 packs to support 50 users.",
            price: "{~interprice|SBT-1Y-FIX-NLC-5~}",
            url: "purl-CCTNU"
        }, {
            id: "collaborator-one-year-maintenance-fixed",
            title: "Collaborator Enterprise - 1 Year Maintenance",
            licenseType: "Fixed License",
            description: "Unlimited number of users based on licenses owned.",
            price: "{~interprice|CS0~}",
            url: ""
        }]
    }
}

export default CollaboratorConfig;