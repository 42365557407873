import React, { Component } from "react";
import ReactGA from 'react-ga';
import './CartReview.scss';

import CartPreviewItem from '../CartPreview/CartPreviewItem.jsx';

class CartReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: []
    } 

    this.onChangeCount = this.onChangeCount.bind(this);
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);  
  }

  onAnalyticsSend(value) {
    ReactGA.event({
      category: "StoreComponent",
      action: "CartReview",
      label: value
    }, ['gadev']);
  }

  componentDidUpdate(prevProps) {    
    if (prevProps.cartProducts !== this.props.cartProducts) {
      this.resolveProducts();
    }
  }

  componentDidMount() {
    this.resolveProducts();
  }

  
  onChangeCount(id, count) {
    const { items } = this.state;

    let cartProducts = [];

    items.forEach(item => {
      item.cartItems.map((cartItem, j) => {
        if (cartItem.product.id === id) {
          cartItem.count = count;
          this.onAnalyticsSend(item.productName, ' Change_Count');
        } 

        cartProducts.push(cartItem);
      });
    });

    this.props.cartUpdateProducts(cartProducts);
  }  
 
  onChangeCheckbox(id, checked) {
    if (this.props.onChangeItem) {
      this.props.onChangeItem(id, checked);
      this.onAnalyticsSend('Checkbox_click')
    }
  }

  resolveProducts() {
    const { cartProducts } = this.props;

    let products = [];
    cartProducts.forEach(cartProduct => {
      if (products.indexOf(cartProduct.product.productName) === -1) {
        products.push(cartProduct.product.productName);
      }
    });

    let items = [];
    products.forEach(product => {
      let cartItems = cartProducts.filter(item => item.product.productName === product);
      if (cartItems.length) {
        items.push({
          productName: cartItems[0].product.productName,
          productIcon: cartItems[0].product.productIcon,
          /**
           * apodrezov: Important - do not copy cartItems object
           */
          cartItems: cartItems.map(item => ( {product: item.product, count: item.count} ))
        });
      }
    });

    this.setState({
      items: items
    });
  }

  render() {
    const { items } = this.state;

    if (typeof items === "undefined")
      return null;

    const checkboxDisabled = this.props.checkboxDisabled ? this.props.checkboxDisabled : false;
    const countPickerDisabled = this.props.countPickerDisabled ? this.props.countPickerDisabled : false;

    return (
      <div className="cart">
          {items.map((item, i) => {
            return (
              <div className="cart-group" key={i}>
                <div className="cart-group-product">
                  <div className={`prod-icon ${item.productIcon}`}></div>
                  <div className="prod-title">{item.productName}</div>
                </div>
                <div className="cart-group-items">
                  {item.cartItems.map((cartItem, j) => (
                    <CartPreviewItem key={j} 
                      item={cartItem}
                      checkboxDisabled={checkboxDisabled}                         
                      countPickerDisabled={countPickerDisabled} 
                      onChangeCount={this.onChangeCount}
                      onChangeCheckbox={this.onChangeCheckbox}
                      />
                  ))}
                </div>
              </div>            
            )

          })}
        </div>
    );
  }
}

export default CartReview;
