export const setSort = sort => ({
    type: 'SET_SORT',
    payload: sort,
})

export const setFilter = (filter, area) => ({
    type: 'SET_FILTER',
    id: area,
    payload: filter,
})

export const clearFilters = (filter) => ({
    type: 'CLEAR_FILTERS',
    payload: filter, 
})

export const clearFilter = (filter) => ({
    type: 'CLEAR_FILTER',
    payload: filter, 
})

export const mobileFilterShow = () => ({
    type: "MOBILE_FILTER_SHOW",
    payload: true
  });
  
  export const mobileFilterHide = () => ({
    type: "MOBILE_FILTER_HIDE",
    payload: false
  });