import React, { Component } from "react";
import InterPrice from '../../../containers/common/InterPrice/InterPrice';

import './SaleItemRadio.scss';

class SaleItemRadio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.checked ? this.props.checked : false,
      name: this.props.name
    }

    this.handleClick = this.handleClick.bind(this);
    this.handleClickCallback = this.handleClickCallback.bind(this); 
  }

  handleClickCallback(checked, value) {
    const { name } = this.state;

    if (this.props.onClick) {
      this.props.onClick(name, value, checked);
    }    
  }

  handleClick(e) {

    const { checked } = this.state;

    if (!this.props.disabled) {
      this.setState({
        checked: !checked
      }, () => this.handleClickCallback(!checked, e.target.value));
    }
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.checked !== this.props.checked)) {
      this.setState({
        checked: this.props.checked
      });
    }
  }

  render() {

    const { name, items, selectedId, disabled, titleRemove } = this.props;

    const cssChecked = (this.state.checked && selectedId) ? "si-radio-active" : "";
    const cssDisabled = this.props.disabled ? "disabled" : "";

    return (
      <div className={`si-radio ${cssChecked} ${cssDisabled}`}>
        {
          items.map((item, key) => {

            let itemName = name + '_' + item.id;
            let itemChecked = disabled ? false : (item.id === selectedId);

            return (
              <div className="si-radio-item" key={key}>
                <input type="radio" 
                  id={itemName}
                  name={name}
                  value={item.id}
                  disabled={disabled}
                  checked={itemChecked}
                  onChange={this.handleClick} />

                <label htmlFor={itemName}>{item.title.replace(titleRemove, '')}
                {
                  disabled ? null : <span className='saleprice'> - <InterPrice value={item.price}/></span>
                }
                </label>
              </div>
              )
          })
        }              
      </div>
    );
  }
}

export default SaleItemRadio;
