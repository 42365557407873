import { combineReducers } from 'redux';

import products from './products';
import interPrice from './interPrice';
//import addons from './addons';
import solutions from './solutions';
import platforms from './platforms';
import marketPlaces from './marketPlace';
import filter from './filter';
import cartPreview from './cartPreview';
import cart from './cart';
import cartMenu from './cartMenu';
import mobileFilter from './mobileFilter';

export default combineReducers({
    interPrice,
    products,
    solutions,
    platforms,
    marketPlaces,
    filter,
    cartPreview,
    cart,
    cartMenu,
    mobileFilter
});