import icon from '../assets/AQTimePro-Logo.svg';

const AQTimeConfig = {
    name: "AQTime Pro",
    url: "aqtime-pro",
    icoClassName: "aqt",
    ico: icon,
    saleitems: {
        floating: [{
            id: "aqtime-pro-floating",
            title: "AQtime Pro - Floating License",
            licenseType: "Floating License",
            description: "Floating (Concurrent) License can be activated for different users and machine combinations, but only one at a time and has a floating license key. The number of running instances of the Software or the number of individuals simultaneously having access to the Software may not exceed at any one time the number of floating seats licensed.",
            price: "{~interprice|AQP-VXX-FLC-WE~}",
            url: "purl-AQTproF"
        }],
        nodeLocked: [{
            id: "aqtime-pro-fixed",
            title: "AQtime Pro - Fixed License",
            licenseType: "Fixed License",
            description: "Fixed License can be activated for use of a single instance of the software on a single specified computer or computing device.",
            price: "{~interprice|AQP-VXX-NLC-WE~}",
            url: "purl-AQTproN"
        }]
    }
}

export default AQTimeConfig;