import React, { Component } from "react";
import './CartPreview.scss';

import { withRouter } from "react-router-dom";

import ProductsStore from '../../../data/products.js';
import ReactGA from 'react-ga'

import CartPreviewItem from './CartPreviewItem.jsx';
import CartSubtotal from '../../../containers/common/CartSubtotal.js';

class CartPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [] // saleitems displayed
    }

    this.onChangeItemCount = this.onChangeItemCount.bind(this);
    this.onClickCartAdd = this.onClickCartAdd.bind(this);
    this.onClickCartBuy = this.onClickCartBuy.bind(this);
  }

  onAnalyticsSend(value) {
    ReactGA.event({
      category: "StoreComponent",
      action: "CartPreview",
      label: value
    }, ['gadev']);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cartPreviewProducts !== this.props.cartPreviewProducts) {
      this.resolveProducts();
    }
  }

  componentDidMount() {
    this.props.cartPreviewDeleteProducts();  

    this.resolveProducts();
  }

  onChangeItemCount(id, count) {
    const { items } = this.state;

    let tmp = [];

    items.forEach(item => {

      tmp.push({
        product: item.product,
        count: (item.product.id === id) ? count : item.count
      });
      if (item.product.id === id) {
        this.onAnalyticsSend(item.product.title + ' Change_Count');
      }
    });

    this.setState({
      items: tmp
    });
  }

  resolveProducts() {
    const { cartPreviewProducts } = this.props;

    if (typeof cartPreviewProducts === "undefined") 
      return;

    let items = [];

    cartPreviewProducts.forEach(product => {      
        items.push({
          product: product
        });
    });

    this.setState({ 
      items: items
    });
  }

  onClickCartAdd() {
    const { items } = this.state;
    
    this.props.cartAddProducts(items);

    this.props.cartMenuShow();
  }

  onClickCartBuy() {
    const { items } = this.state;

    this.props.cartAddProducts(items);
    this.props.cartCheckout();
  }

  render() {

    const { items } = this.state;

    // for redux state - https://blog.jakoblind.no/react-component-not-updating/
    const subTotalItems = Object.assign([], items);

    if (items.length === 0) {
      return (
        <div className="cart-preview">
          <div className="cart-preview-header">
            <div className="title">Your cart is empty</div>
          </div>
          <div className="cart-preview-content">
            Please configure the license or choose any add-ons. 
          </div>
        </div>        
      );
    }
    return (
      <div className="cart-preview">
        <div className="cart-preview-header">
          <div className="title">Cart overview</div>
          <div className="count">{items.length} items</div>
        </div>
        <div className="cart-preview-content">
          {items.map((cartItem, i) => (
            <CartPreviewItem key={i} 
              item={cartItem}
              checkboxDisabled={true} 
              onChangeCount={this.onChangeItemCount}
            />
          ))}
        </div>
        <div className="cart-preview-footer">
          <CartSubtotal items={subTotalItems} />

          <div className="btn-preview btn-preview-buy-now" onClick={() => {this.onClickCartBuy(); this.onAnalyticsSend('Buy')}}>Buy Now</div>
          <div className="btn-preview btn-preview-add-cart" onClick={() => {this.onClickCartAdd(); this.onAnalyticsSend('CartAdd_click')}}>Add to cart</div>
        </div>
      </div>
    );
  }
}

export default withRouter(CartPreview);
