import icon from '../assets/TestExecute-Logo.svg';

const TestExecuteConfig = {
    name: "TestExecute",
    url: "testexecute",
    icoClassName: "tex",
    ico: icon,
    saleitems: {
        floating: [{
            id: "testexecute-floating",
            title: "TestExecute - 1 Year Maintenance",
            licenseType: "Floating License - 1 Year Subscription",
            description: "TestExecute requires a TestLeft or TestComplete license.",
            price: "{~interprice|TXE-VXX-FLC~}",
            url: "purl-TX10FL"
        }],
    }
}

export default TestExecuteConfig;