import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as productActions from "../../../actions/productActions";
import InterPrice from '../../../components/common/InterPrice/InterPrice.jsx';

const mapStateToProps = (state) => ({
    interPrice: state.interPrice.interPrice,
    region: state.interPrice.region
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({...productActions}, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(InterPrice);
