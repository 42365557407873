import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cartPreviewActions from "../actions/cartPreviewActions";
import TestExecutePage from "../pages/products/TestExecutePage.jsx";

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(cartPreviewActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TestExecutePage);
