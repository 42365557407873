import icon from '../assets/TestLeft-Logo.svg';

const TestLeftConfig = {
    name: "TestLeft",
    url: "testleft",
    icoClassName: "tl",
    ico: icon,
    saleitems: {
        floating: [{
            id: "testleft-floating",
            title: "TestLeft - Floating License",
            licenseType: "Floating License",
            description: "Floating (Concurrent) License can be activated for different users and machine combinations, but only one at a time and has a floating license key. The number of running instances of the Software or the number of individuals simultaneously having access to the Software may not exceed at any one time the number of floating seats licensed. Subscription - you may install and use the Software solely as permitted by the license type purchased on compatible devices during the applicable Subscription Term.",
            price: "{~interprice|TL-VXX-FLC-1Y~}",
            url: "purl-tlflc1y"
        }],
        nodeLocked: [{
            id: "testleft-fixed",
            title: "TestLeft - Fixed License",
            licenseType: "Fixed License",
            description: "Fixed License can be activated for use of a single instance of the software on a single specified computer or computing device. Subscription - you may install and use the Software solely as permitted by the license type purchased on compatible devices during the applicable Subscription Term.",
            price: "{~interprice|TL-VXX-NLL~}",
            url: "purl-tlnlc1y"
        }]
    }
}

export default TestLeftConfig;