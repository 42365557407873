const initialState = {
  marketPlaces: null,
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case "SET_MARKETPLACES":
        return {
          ...state,
          marketPlaces: action.payload,
        };
      default:
        return state;
    }
  };
  