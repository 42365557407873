import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import ReactGA from 'react-ga';

import createStore from './store';
import rootReducer from './reducers/rootReducer';

import HomePage from './containers/home/HomePage';
import CartReviewPage from './containers/CartReviewPage';
import TestCompletePage from './containers/TestCompletePage';
import AQTimePage from './containers/AQTimePage';
import TestLeftPage from './containers/TestLeftPage';
import CollaboratorPage from './containers/CollaboratorPage';
import TestExecutePage from './containers/TestExecutePage';
import ReadyAPIPage from './containers/ReadyAPIPage';
import PageNotFound from './pages/NotFoundPage';

import { createBrowserHistory } from 'history';

import 'semantic-ui-css/semantic.css';
import './scss/styles.scss';


const store = createStore(rootReducer);
const history = createBrowserHistory({
  //basename: process.env.PUBLIC_URL
});

history.listen((location, action) => {

  // scroll page up only there are no params (required for mobile version)
  if (location.search.length === 0) {
    setTimeout(function(){
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });  
    }, 100);  
  }  
});

ReactGA.initialize([{
    trackingId: 'UA-404707-42',
    gaOptions: {
      name: 'gadev'
    }
  }], {
    debug: false,
    titleCase: false,
    alwaysSendToDefaultTracker: false
  });

ReactDOM.render(
    <Provider store={store}>
      <Router history={history} basename="/">
        <Switch>
          <Route exact path='/store/' component={HomePage}/>
          <Route exact path='/store/cart/' component={CartReviewPage}/>
          <Route exact path='/store/testcomplete/' component={TestCompletePage}/>
          <Route exact path='/store/aqtime-pro/' component={AQTimePage}/>
          <Route exact path='/store/testleft/' component={TestLeftPage}/>
          <Route exact path='/store/collaborator/' component={CollaboratorPage}/>
          <Route exact path='/store/testexecute/' component={TestExecutePage}/>
          <Route exact path='/store/readyapi/' component={ReadyAPIPage}/>
          <Route component={PageNotFound}/>
        </Switch>
      </Router>
    </Provider>,
    document.getElementById('root')
);