const initialState = {
  filterBy: "",
  selectedItems: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_SORT":
      return {
        ...state,
        filterBy: action.payload
      };

    case "SET_FILTER":

      // remove filter
      for (let i = 0; i< state.selectedItems.length; i++) {
        // if ((Object.getOwnPropertyNames(state.selectedItems[i])[0] === action.id) && (state.selectedItems[i][action.id].name === action.payload.name)) {
        if ((Object.getOwnPropertyNames(state.selectedItems[i])[0] === action.id) && (state.selectedItems[i][action.id]===(action.payload))) {
          state.selectedItems.splice(i,1);
          return {
            ...state,
            selectedItems: [...state.selectedItems]
          }
        }
      }

      // add filter
      return {
        ...state,
        selectedItems: [...state.selectedItems, {[action.id]: action.payload }]
      }

    case "CLEAR_FILTERS":
      return {
        ...state,
        selectedItems: []
      };

    case "CLEAR_FILTER":
      state.selectedItems.splice(action.payload,1);
      return {
        ...state,
        selectedItems: [...state.selectedItems]
      }

    default:
      return state;
  }
};
