import React, { Component, createRef } from "react";
import { Container, Card, Grid, Ref, Sticky } from "semantic-ui-react";
import axios from "axios";
import ReactGA from 'react-ga';
import {
  withRouter
} from "react-router-dom";
import Helmet from "react-helmet";

import ProductCard from "../components/home/Card.jsx";
import AddonCard from "../components/home/AddonCard.jsx";
import Filter from "../containers/home/Filter";
import MobileFiter from "../components/home/MobileFilter";
import LeftMenu from "../components/home/LeftMenu.jsx";

import queryString from "query-string";
import htmlCleaner from "../utils/htmlCleaner.js";
import ProductsStore from '../data/products.js';

import DefaultTemplate from '../containers/templates/DefaultTemplate';
import Loader from '../components/common/Loader/Loader.jsx';

import config from "../config.js";

import "./HomePage.scss";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state ={
      width: window.innerWidth,
      height: window.innerHeight,
      active: true
    }
    this.contextRef = createRef();
    this.fetchData();
    this.setupFilter();

    this.currentProduct = '';
  }

  onAnalyticsEventClick(area, value) {
    ReactGA.event({
      category: "StoreComponent",
      action: area,
      label: value,
    }, ['gadev']);
  }

  setupFilter() {
    const { setFilter } = this.props;
    let searchObj = queryString.parse(window.location.search);
    if (searchObj) {
      if (searchObj.solutions) {      
        // if(searchObj.solutions === 'Test Management   BDD') {
        //   searchObj.solutions = 'Test Management + BDD'
        // }
        let solutions = searchObj.solutions.split(',');
        if (solutions.length) {
          solutions.forEach(item => { 
            if (item === 'Test Management   BDD') {
              item = 'Test Management + BDD'
            }
            setFilter(htmlCleaner(item), 'Solutions')
          });
        }
      }

      if (searchObj.platforms) {      
        let platforms = searchObj.platforms.split(',');
        if (platforms.length) {
          platforms.forEach(item => { setFilter(htmlCleaner(item), 'Platforms')});
        }
      } 
      
      if (searchObj.marketPlaces) {      
        let marketPlaces = searchObj.marketPlaces.split(',');
        if (marketPlaces.length) {
          marketPlaces.forEach(item => { setFilter(htmlCleaner(item), 'MarketPlaces')});
        }
      }  
      if (searchObj.products) {      
        let products = searchObj.products.split(',');
        if (products.length) {
          products.forEach(item => { setFilter(htmlCleaner(item), 'QueryProducts')});
        }
      }  
      if (searchObj.productAddons) {      
        let addons = searchObj.productAddons.split(',');
        if (addons.length) {
          addons.forEach(item => { setFilter(htmlCleaner(item), 'ProductAddons')});
        }
      }  

      if (searchObj.product) {  
        this.currentProduct = searchObj.product;
      }
    }    
  }

  fetchData() {
    const { setProducts, setAddons, setSolutions, setMarketPlaces, setPlatforms } = this.props;

    axios.get(config.api.productsUrl).then(response => {
      setProducts(
        response.data.customtableitem_sb_Products[0].sb_Products
      );
    });
      setAddons(
        ProductsStore.getAddons()
      );
    axios.get(config.api.solutionsUrl).then(response => {
      setSolutions(
        response.data.customtableitem_sb_ProductSolutions[0].sb_ProductSolutions
      )
    });
    axios.get(config.api.marketPlaceUrl).then(response => {
      setMarketPlaces(
        response.data.customtableitem_sb_ProductMarketPlaces[0].sb_ProductMarketplace
      )
    });
    axios.get(config.api.platformsUrl).then(response => {
      setPlatforms(
        response.data.customtableitem_sb_ProductPlatforms[0].sb_ProductPlatforms
      )
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter.selectedItems !== this.props.filter.selectedItems) {
        this.updateHistory()
    }
  }

  updateHistory() {
    const { filter } = this.props;

    let queryParams = [];

    if (filter.selectedItems.length) { 

      let solutionParams = [],
      platformParams = [],
      marketPlaceParams = [],
      queryProducts = [],
      productAddonsParams = [];

      filter.selectedItems.forEach((selectedItem) => {
        if (!!selectedItem.Solutions) {
          solutionParams.push(selectedItem.Solutions);
        }

        if (!!selectedItem.Platforms) {
          platformParams.push(selectedItem.Platforms);
        }

        if (!!selectedItem.MarketPlaces) {
          marketPlaceParams.push(selectedItem.MarketPlaces);
        }

        if (!!selectedItem.ProductAddons) {
          productAddonsParams.push(selectedItem.ProductAddons);
        }

        if (!!selectedItem.QueryProducts) {
          queryProducts.push(selectedItem.QueryProducts);
        }                    
      });


      if (solutionParams.length)
        queryParams.push("solutions=" + solutionParams);

      if (platformParams.length)
        queryParams.push("platforms=" + platformParams);          
        
      if (marketPlaceParams.length) 
        queryParams.push("marketPlaces=" + marketPlaceParams);

      if (productAddonsParams.length) 
        queryParams.push("productAddons=" + productAddonsParams);

      if (queryProducts.length) 
        queryParams.push("products=" + queryProducts);        
    }

    let searchObj = queryString.parse(window.location.search);

    /*// Product filter
    if (searchObj && searchObj.products) {
      queryParams.push("products=" + searchObj.products);
    }*/

    // Product popup (overrides all other params)
    if (searchObj && searchObj.product) {
      queryParams = [];
      queryParams.push("product=" + searchObj.product);
    }

    let url = window.location.pathname.replace(process.env.PUBLIC_URL, "");
    if (queryParams.length)
      url = url + '?' + queryParams.join("&");
      
     this.props.history.push(url);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
 
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  render() {
    const { allProducts, products, addons, solutions, integrations, technologies, marketPlaces, platforms, isReady, cartAddProducts, cartMenuShow, show, mobileFilterShow, mobileFilterHide } = this.props;
    let {active, width} = this.state;
    if (width > 920) {
      active = true}
      else active = false

   // Needs to be refactored, move to LeftMenu event handlers
   //  this.updateHistory();

    return (
      <DefaultTemplate>
        <Helmet title = {'Store | SmartBear Software'}/>
        <div className={`products-app`}>
        {!isReady ? <Loader /> :       
          <Container>
            <Grid doubling stackable columns="equal">
              <div className="mobile-filter"></div>
              <Ref innerRef = {this.contextRef}>
              <Grid.Row stretched>
                <Grid.Column width={width > 921 ? 5 : null}>
                  <Sticky active={active } context={this.contextRef} offset={width > 921 ? 65 : 115}>
                  <LeftMenu key={products} products={products} {...solutions} {...integrations} {...technologies} {...marketPlaces} {...platforms} show={show} />
                  </Sticky>
                </Grid.Column>
                <Grid.Column width={width > 921 ? 11 : 16}>
                <MobileFiter products={products} addons={addons} mobileFilterShow={mobileFilterShow} mobileFilterHide={mobileFilterHide} show={show}/>
                <Filter products={products} addons={addons} analyticsClick={this.onAnalyticsEventClick.bind(this)}/>
                  <Card.Group doubling stackable itemsPerRow={width > 921 ? width < 1368 ? 2 : 3 : 2} data-testid="products-group">
                    {products.map((product, i) => (
                      <ProductCard key={i} product={product} products={allProducts} integrations={integrations} analyticsClick={this.onAnalyticsEventClick.bind(this)}/>
                    ))}
                  </Card.Group>
                  {addons !== null && addons.length > 0 ? (<div className="addons-hero">
                    <div className="container">
                      <div className="addons-hero-title">Trainings, Certification and Add-Ons</div>
                      <div className="addon-hero-text">
                        Purchase additional trainings, certifications, and add-ons for your favorite products.                
                      </div>
                    </div>
                  </div>) : ''}
                  <Card.Group doubling stackable itemsPerRow={width > 921 ? width < 1368 ? 2 : 3 : 2} data-testid="addons-group">
                      {addons !== null ? addons.map((addon, i) => (
                        <AddonCard key={i} addon={addon} cartAddProducts={cartAddProducts} cartMenuShow={cartMenuShow} analyticsClick={this.onAnalyticsEventClick.bind(this)}/>
                      )): ''}
                  </Card.Group >
                </Grid.Column>
              </Grid.Row>
              </Ref>
            </Grid>
          </Container>
        }
        </div>
      </DefaultTemplate>
    );
  }
}

export default withRouter(HomePage);
