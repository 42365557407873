import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cartActions from "../../actions/cartActions";
import CartMenu from "../../components/common/CartMenu/CartMenu.jsx";

const mapStateToProps = (state) => ({
  cartProducts: state.cart.cartProducts,
  cartProductsAdded: state.cart.cartProductsAdded,
  show: state.cartMenu.show
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(cartActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CartMenu);
