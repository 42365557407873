import React, { Component } from "react";
import NumberPicker from "react-widgets/NumberPicker";
import InterPrice from '../../../containers/common/InterPrice/InterPrice';

import ProductsStore from '../../../data/products.js';

import "react-widgets/styles.css";

class  CartPreviewItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [] 
    }

    this.onChangeCount = this.onChangeCount.bind(this);
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);    
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.item !== this.props.item)) {
      this.resolveProducts();
    }
  }

  componentDidMount() {
    this.resolveProducts();
  }

  resolveProducts() {
    const { product } = this.props.item;
    const count = this.props.item.count || 1;

    let items = [];
    let saleitems = ProductsStore.getSaleItems(); 

      // TestComplete pre-processing
      if (ProductsStore.isTestComplete(product.id)) {

        let ids = product.id.split("-");
        let licenseType = ids.splice(1,1);
        let prefixId = `testcomplete-${licenseType}-`;
        ids = ids.slice(1);
    
        switch(ids[0]) {
          case 'pro':
            items.push({
              product: product,
              countPickerDisabled: false,
              priceDisabled: false,
              count: count
            });
    
            ['desktop', 'mobile', 'web', 'addon-te', 'addon-device-cloud-iq'].forEach(id => {
              let item = saleitems.filter(si => si.id === prefixId + id);
              if (item.length) {
                items.push({
                  product: item[0],
                  countPickerDisabled: true,
                  checkboxDisabled: true,
                  priceDisabled: true,
                  count: count
                });
              }          
            });
            break;
    
          case 'desktop':
          case 'mobile':
          case 'web':
          case 'addon-te':
          case 'addon-device-cloud-iq':
            let platform = saleitems.filter(si => si.id === prefixId + 'platform');
    
            if (platform.length) {
              items.push({
                product: platform[0],
                countPickerDisabled: false,
                checkboxDisabled: false,
                priceDisabled: false,
                count: count
              });
            }        
    
            ids.forEach(id => {
              let item = saleitems.filter(si => si.id === prefixId + id);
              if (item.length) {
                items.push({
                  product: item[0],
                  countPickerDisabled: true,
                  checkboxDisabled: false,
                  priceDisabled: false,
                  count: count
                });            
              }          
            });
    
            break;
        }   

      } else {
        // All other products
        items.push({
          product: product,
          countPickerDisabled: false,
          checkboxDisabled: false,
          priceDisabled: false,
          count: count
        });
      }

    this.setState({ 
      items: items
    });
  }  

  onChangeCount(value) {
    if (this.props.onChangeCount) {
      this.props.onChangeCount(this.props.item.product.id, value);
    }
  }

  onChangeCheckbox(e) {
    if (this.props.onChangeCheckbox) {
      let name = e.target.name;
      const { product } = this.props.item;
      const count = this.props.item.count || 1;

      if (ProductsStore.isTestComplete(product.id) && (product.id.indexOf("-pro") === -1)) {
        name = name + ":" + product.id + ":" + count;
      }

      this.props.onChangeCheckbox(name, e.target.checked);
    }
  }  

  render() {
    const { items } = this.state;

    return (
      <>
      {items.map((item, i) => {
          const count = item.count ? item.count : 1;
          
          const countPickerDisabled = this.props.countPickerDisabled || item.countPickerDisabled;
         
          return (
            <div className="cart-preview-item" key={item.product.id + "-" + i}>
              {this.props.checkboxDisabled ?
                null : (item.checkboxDisabled ?
                <div className="cart-preview-item-checkbox disabled"></div>
                :
                <div className="cart-preview-item-checkbox">
                  <input type="checkbox" 
                    id={item.product.id + "-" + i}
                    name={item.product.id} 
                    onChange={this.onChangeCheckbox} />
                </div>
              )}
              <div className="cart-preview-item-title">
                {
                  this.props.checkboxDisabled || item.checkboxDisabled 
                  ?
                  item.product.title
                  :
                  <label htmlFor={item.product.id + "-" + i}>{item.product.title}</label>
                }                
                {item.product.licenseType ? <div className="license-type">{item.product.licenseType}</div> : null}          
              </div>
              <div className="cart-preview-item-dots"></div>
              <div className="cart-preview-item-count">
                {countPickerDisabled
                ? 
                  <div className="disabled">[x{count}]</div>
                :
                  <NumberPicker defaultValue={1} value={count} min={1} max={1000} onChange={this.onChangeCount} />
                }
              </div>
              <div className="cart-preview-item-price">
                {item.priceDisabled 
                ?
                null 
                :
                <InterPrice value={item.product.price} count={count} />
                }
              </div>
            </div>
          )
      })}
      </>
      
    );
  }
}

export default CartPreviewItem;
