import icon from '../assets/TestComplete-Logo.svg';

const TestCompleteConfig = {
    name: "TestComplete",
    url: "testcomplete",
    icoClassName: "tc",
    ico: icon,
    saleitems: {
        floating: [{
                id: "testcomplete-floating-pro",
                title: "TestComplete Pro",
                licenseType: "Floating License - Subscription",
                description: "Includes all the features of the TestComplete Platform with additional Desktop, Web and Mobilde modules.",
                price: "{~interprice|TC-PRO-FLC~}",
                url: "purl-tcpbfl"
            },{
                id: "testcomplete-floating-platform",
                title: "TestComplete Base",
                licenseType: "Floating License - Subscription",
                description: "TestComplete Platfom provides a test IDE with foudational components for automated testing, recording and integration with third-party tools.",
                price: "{~interprice|TCP-VXX-FLC~}",
                url: ""
            },{
                id: "testcomplete-floating-desktop",
                title: "Desktop Module",
                description: "Create automated UI tests using script-free record-and-playback, or script in languages (e.g. Python, JavaScript, VB.NET).",
                price: "{~interprice|TCD-VXX-FLC~}",
                url: "purl-TC10PDFL"
            },{
                id: "testcomplete-floating-mobile",
                title: "Mobile Module",
                description: "Separate the UI object identification from the test logic to build stable, easy-to-maintain automated tests. ",
                price: "{~interprice|TCM-VXX-FLC~}",
                url: "purl-TC10PMFL"
            },{
                id: "testcomplete-floating-web",
                title: "Web Module",
                description: "Build robust and stable tests using intelligent object recognition mechanism for dynamic elements and technologies like flex, flash, AJAX, JavaScript, HTML5, and AngularJS.",
                price: "{~interprice|TCW-VXX-FLC~}",
                url: "purl-TC10PWFL"
            },{
                id: "testcomplete-floating-desktop-web",
                title: "Desktop + Web Modules",
                description: "Includes functionality of the Desktop and Web modules",
                price: "{~interprice|TCDW-VXX-FLC~}",
                url: "purl-TC10PDWFL"
            },{
                id: "testcomplete-floating-desktop-mobile",
                title: "Desktop + Mobile Modules",
                description: "Includes functionality of the Desktop and Mobile modules",
                price: "{~interprice|TCDM-VXX-FLC~}",
                url: "purl-TC10PDMFL"
            },{
                id: "testcomplete-floating-mobile-web",
                title: "Mobile + Web Modules",
                description: "Includes functionality of the Mobile and Web modules",
                price: "{~interprice|TCWM-VXX-FLC~}",
                url: "purl-TC10PWMFL"
            },{
                id: "testcomplete-floating-desktop-mobile-web",
                title: "Desktop + Web + Mobile Modules",
                description: "Includes functionality of the Desktop, Web and Mobile modules",
                price: "{~interprice|TCDWM-VXX-FLC~}",
                url: "purl-TC10PDWMFL"
            },{
                id: "testcomplete-floating-addon-te",
                title: "TestExecute Add-on",
                description: "Scale TestComplete or TestLeft Tests",
                price: "",
                url: ""
            },
            {
                id: "testcomplete-floating-addon-device-cloud-iq",
                title: "Intelligent Quality Add-on",
                description: "For access to AI-powered visual recognition, PDF support, and automatic web audits for performance, accessibility and SEO",
                price: "",
                url: ""
            }
        ],
        nodeLocked: [{
            id: "testcomplete-nodeLocked-pro",
            title: "TestComplete Pro",
            licenseType: "Fixed License - Subscription",
            description: "Includes all the features of the TestComplete Platform with additional Desktop, Web and Mobilde modules.",
            price: "{~interprice|TC-PRO-NLC~}",
            url: "purl-tcpbnl"
        },{
            id: "testcomplete-nodeLocked-platform",
            title: "TestComplete Base",
            licenseType: "Fixed License - Subscription",
            description: "TestComplete Platfom provides a test IDE with foudational components for automated testing, recording and integration with third-party tools.",
            price: "{~interprice|TCP-VXX-NLC~}",
            url: ""
        },{
            id: "testcomplete-nodeLocked-desktop",
            title: "Desktop Module",
            description: "Create automated UI tests using script-free record-and-playback, or script in languages (e.g. Python, JavaScript, VB.NET).",
            price: "{~interprice|TCD-VXX-NLC~}",
            url: "purl-TC10PDNL"
        },{
            id: "testcomplete-nodeLocked-mobile",
            title: "Mobile Module",
            description: "Separate the UI object identification from the test logic to build stable, easy-to-maintain automated tests. ",
            price: "{~interprice|TCM-VXX-NLC~}",
            url: "purl-TC10PMNL"
        },{
            id: "testcomplete-nodeLocked-web",
            title: "Web Module",
            description: "Build robust and stable tests using intelligent object recognition mechanism for dynamic elements and technologies like flex, flash, AJAX, JavaScript, HTML5, and AngularJS.",
            price: "{~interprice|TCW-VXX-NLC~}",
            url: "purl-TC10PWNL"
        },{
            id: "testcomplete-nodeLocked-desktop-web",
            title: "Desktop + Web Modules",
            description: "Includes functionality of the Desktop and Web modules",
            price: "{~interprice|TCDW-VXX-NLC~}",
            url: "purl-TC10PDWNL"
        },{
            id: "testcomplete-nodeLocked-desktop-mobile",
            title: "Desktop + Mobile Modules",
            description: "Includes functionality of the Desktop and Mobile modules",
            price: "{~interprice|TCDM-VXX-NLC~}",
            url: "purl-TC10PDMNL"
        },{
            id: "testcomplete-nodeLocked-mobile-web",
            title: "Mobile + Web Modules",
            description: "Includes functionality of the Mobile and Web modules",
            price: "{~interprice|TCWM-VXX-NLC~}",
            url: "purl-TC10PWMNL"
        },{
            id: "testcomplete-nodeLocked-desktop-mobile-web",
            title: "Desktop + Web + Mobile Modules",
            description: "Includes functionality of the Desktop, Web and Mobile modules",
            price: "{~interprice|TCDWM-VXX-NLC~}",
            url: "purl-TC10PDWMNL"
        },{
            id: "testcomplete-nodeLocked-addon-te",
            title: "TestExecute Add-on",
            description: "Scale TestComplete or TestLeft Tests",
            price: "",
            url: ""
        },{
            id: "testcomplete-nodeLocked-addon-device-cloud-iq",
            title: "Intelligent Quality Add-on",
            description: "For access to AI-powered visual recognition, PDF support, and automatic web audits for performance, accessibility and SEO",
            price: "",
            url: ""
        }]
    },
    addons: [{
        id: "testcomplete-addon-device-cloud-iq",
        title: "Intelligent Quality Add-on",
        description: "For access to AI-powered visual recognition, PDF support, and automatic web audits for performance, accessibility and SEO",
        price: "{~interprice|TC-IQ-SBN-1Y~}",
        url: "purl-TCIQ1YN"
    },{
        id: "testcomplete-addon-te",
        title: "TestExecute Add-on",
        description: "Scale TestComplete or TestLeft Tests",
        price: "{~interprice|TXE-VXX-FLC~}",
        url: "purl-TX10FL"
    }
    ]
}

export default TestCompleteConfig;