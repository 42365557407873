import React, { Component } from "react";
import axios from "axios";

import Header from '../../components/header/Header/Header.jsx';
import CartCheckout from '../../containers/common/CartCheckout.js';
import CartCookie from '../../containers/common/CartCookie.js';
import CartMenu from '../../containers/common/CartMenu.js';
import Footer from '../../components/footer/footer';

import config from "../../config.js";

class DefaultTemplate extends Component {
  constructor(props) {
    super(props);

    this.fetchData();
  }

  fetchData() {
    const { setInterPrice, setRegion} = this.props;
    axios.get(config.api.interpriceUrl).then(response => {
      setInterPrice(
        response.data.customtableitem_sb_InternationalPricings[0].sb_InternationalPricing
      );
    });

    axios.get(config.api.regionUrl).then(response => {
      setRegion(
        response.data.zone
      );
    });
  }

  render() {
    return (
      <div className="">
        <CartCookie />
        <CartCheckout />
        <CartMenu />
        <Header />
        {this.props.children} 
        <Footer />
      </div>
    );
  }
}

export default DefaultTemplate;
