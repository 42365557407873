import React, { Component, createRef } from "react";
import { event } from "react-ga";
import { Menu, Input } from "semantic-ui-react";
import { withRouter } from "react-router";

import ReactGA from 'react-ga';

import "./MobileFilter.scss";

class MobileFilter extends Component {
    constructor(props) {
      super(props);

      this.overlay = createRef();
      this.handleClick = this.handleClick.bind(this);
    }

    onAnalyticsSend(value) {
        ReactGA.event({
          category: "StoreComponent",
          action: "MobileFilter",
          label: value
        }, ['gadev']);
      }

    handleClick() {
        if (!this.props.show) {
            this.props.mobileFilterShow();
            document.body.style.overflow = 'hidden';
            this.overlay.current.classList.add("show");
        }
        else {
            this.props.mobileFilterHide();
            document.body.style.overflow = 'auto';
            this.overlay.current.classList.remove("show");
        }
    }
    render() {
        const { products, addons, show } = this.props;
    return (
        <Menu secondary className="filter-button">
            <div className="overlay" ref={this.overlay} onClick={() => {this.handleClick(); this.onAnalyticsSend('Overlay_click')}}></div>
            <div className="filter-button-area">
                <div className="left-place">
                    <div className="filters-button" onClick={() => {this.handleClick(); this.onAnalyticsSend('Button_click')}}><i className={`fal ${show === false ? 'fa-list' : 'fa-times'}`}></i> {show === false ? 'Filters' : 'Close'} </div>
                </div>
                <div className="right-place">
                    <div className="selected-filters">
                        <div className='count-name'>{products.length + addons.length + ' ' + 'results'}</div>
                    </div>
                </div>
            </div>
        </Menu>
      );}
}

export default withRouter(MobileFilter);