import React from "react";
import { event } from "react-ga";
import { Menu, Input } from "semantic-ui-react";

import "./Filter.scss";

const Filter = ({ setSort, filterBy, selectedItems, clearFilter, clearFilters, addons, products, analyticsClick }) => {

  function clearAll() {
    clearFilters();
    analyticsClick('Filter', 'ClearFilters');
  } 

  function handleChange(e) {
    let target = e.target;
    let value = target.value;

    setSort(filterBy = value)
    // if (value.length > 3) {
    //   setTimeout(() => analyticsClick('Search', value), 5000)
    // }
  }

  return (
    <Menu secondary className="sort-menu">
      <Menu.Menu position="left">
      <div className="selected-filters">
      <div className='count-name'>{products.length + addons.length + ' ' + 'results'}</div>
          {selectedItems.map((selectedItem, key)=>{
            return <div className="selected-filters-item" key={key}>{selectedItem[Object.getOwnPropertyNames(selectedItem)[0]]}
              <span className='selected-filters-item-clear' onClick={clearFilter.bind(this, key)}><i className="fal fa-times"></i></span></div>
          })}
         
          {(selectedItems && selectedItems.length > 1) ? <div className="selected-filters-item-clearall" onClick={()=>clearAll()}>Clear All
            <span className='selected-filters-item-clear'><i className="fal fa-times"></i></span></div> : ""}
        </div>
      </Menu.Menu>
      <Menu.Menu position="right">
          <Input icon={<i className="fal fa-search"></i>}  iconPosition='left' className='searchbar-input' placeholder='Search...' value={filterBy} onChange={handleChange.bind(this)} />
      </Menu.Menu>
    </Menu>
  );
};

export default Filter;
