import React, { Component } from "react";
import './CartReviewPage.scss';

import { Link } from "react-router-dom";
import Helmet from "react-helmet";

import DefaultTemplate from '../containers/templates/DefaultTemplate.js'
import CartReview from '../containers/common/CartReview.js';
import CartSubtotal from '../containers/common/CartSubtotal.js';

import Breadcrumb from '../components/common/Breadcrumb/Breadcrumb.jsx';

import ProductStore from "../data/products.js";

class CartReviewPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      removeItemIds: []
    }
 
    this.onChangeItem = this.onChangeItem.bind(this);
    this.onClickRemove = this.onClickRemove.bind(this);    
    this.onClickCheckout = this.onClickCheckout.bind(this);    
  }

  onChangeItem(id, checked) {

    let removeItemIds = [... this.state.removeItemIds];

    if (checked && (removeItemIds.indexOf(id) === -1)) {
      removeItemIds.push(id);
    } else if (!checked && (removeItemIds.indexOf(id) !== -1)) {
      removeItemIds = removeItemIds.filter(item => item !== id); 
    }

    this.setState({
      removeItemIds: removeItemIds
    });
  }

  onClickRemove() {
    const { removeItemIds } = this.state;
    let { cartProducts } = this.props;

    if (removeItemIds.length) {

      let oldTCBaseId = "";
      let newTCBaseId = "";
      let newTCBaseCount = 1;

      removeItemIds.forEach(id => {
        // if TC
        if (id.indexOf(":") !== -1) {
          let modules = id.split(":");

          // if TC base
          if (modules[0].indexOf("-pro") === -1) {

            oldTCBaseId = modules[1];
            if (newTCBaseId.length === 0) {
              newTCBaseId = oldTCBaseId;
              newTCBaseCount = parseInt(modules[2]);
            }

            let module = modules[0].split("-");
            module = module[module.length - 1];

            switch(module) {
              case 'platform':
                newTCBaseId = "";
                break;

              case 'desktop':
              case 'mobile':
              case 'web':   
                newTCBaseId = newTCBaseId.replace("-" + module, "");
                break;            
            }
          }
        }
      });


      if (oldTCBaseId.length) {
        this.props.cartDeleteProductByIDs([oldTCBaseId]);

        if (newTCBaseId.length) {          
          let saleitem = ProductStore.getSaleItemById(newTCBaseId);          
          if (saleitem) {
            this.props.cartAddProducts([{ 
              product: saleitem,
              count: newTCBaseCount
            }]);
          }
        }
      }

      this.props.cartDeleteProductByIDs(removeItemIds);

      this.setState({
        removeItemIds: []
      });
    }
  }

  onClickCheckout() {
    this.props.cartCheckout();
  }

  render() {
    let { cartProducts } = this.props;
    let { removeItemIds } = this.state;

    if ((typeof cartProducts === "undefined")) {
      cartProducts = [];
    }

    let cnt = 0;
    cartProducts.forEach(cartProduct => {
      let count = cartProduct.count ? cartProduct.count : 1
      count = count > 0 ? count : 1; 

      cnt += count;
     });

    return (
      <DefaultTemplate>
        <Helmet title = {'Store - Cart Review | SmartBear Software'}/>

        <div className="page page-cart">
          <div className="page-header">            
            <Breadcrumb step="review"></Breadcrumb>
            <div className="info">
              <div className="info-title">Cart Overview</div>
              <div className="info-count">{cnt} item{cnt === 1 ? "" : "s"}</div>
            </div>
          </div>          
          <div className="page-content">
          { cnt ? 
            <div className="page-cart-content">
              <CartReview onChangeItem={this.onChangeItem} />

              <div className="page-cart-actions">
                <div className={`btn-cart-remove ${removeItemIds.length ? "active" : ""}`} onClick={this.onClickRemove} disabled={removeItemIds.length ? false : true}>Remove selected</div>
              </div>

              <div className="page-cart-checkout">

                <div className="subtotal">
                  <CartSubtotal items={cartProducts} />
                </div>
                <div className="checkout">
                  <div className="btn-checkout" onClick={this.onClickCheckout}>Proceed to checkout</div>
                </div>
              </div>
            </div>
            :
            <div className="page-cart-message">Your cart is empty. Please <Link to="/store/">choose a product and configure a license</Link>.</div>            
          }
            
          </div>                  
        </div>
      </DefaultTemplate>
    );
  }
}

export default CartReviewPage;
