export const cartAddProducts = products => ({
  type: "CART_ADD_PRODUCTS",
  payload: products
});

export const cartUpdateProducts = products => ({
  type: "CART_UPDATE_PRODUCTS",
  payload: products
});

export const cartDeleteProductByIDs = productIds => ({
  type: "CART_DELETE_PRODUCTS",
  payload: productIds
});

export const cartDeleteProducts = () => ({
  type: "CART_DELETE_ALL_PRODUCTS",
  payload: []
});

export const cartCheckout = () => ({
  type: "CART_CHECKOUT",
  payload: true
});

export const cartMenuShow = () => ({
  type: "CART_MENU_SHOW",
  payload: true
});

export const cartMenuHide = () => ({
  type: "CART_MENU_HIDE",
  payload: false
});

export const cartResetProductsAdded = () => ({
  type: "CART_RESET_ADDED_PRODUCTS",
  payload: []
});
