import React, { Component } from "react";
import './CartMenu.scss';

import { withRouter, Link } from "react-router-dom"; 
import Helmet from "react-helmet";

import ReactGA from 'react-ga';

import CartReview from '../../../containers/common/CartReview.js';
import CartSubtotal from '../../../containers/common/CartSubtotal.js';

class CartMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {

    }

    this.sidebar = React.createRef(); 
    this.sidebarMenu = React.createRef(); 
    this.sidebarContent = React.createRef(); 
    this.sidebarHeader = React.createRef(); 
    this.sidebarFooter = React.createRef(); 

    this.onClickClose = this.onClickClose.bind(this); 
    this.onClickCheckout = this.onClickCheckout.bind(this);  
    this.onClickViewCart = this.onClickViewCart.bind(this);

    this.handleResize = this.handleResize.bind(this);
  }

  onAnalyticsSend(value) {
    ReactGA.event({
      category: "StoreComponent",
      action: "CartMenu",
      label: value
    }, ['gadev']);
  }

  onClickClose() {
    this.props.cartMenuHide();
    this.props.cartResetProductsAdded();
  }

  onClickCheckout() {
    this.props.cartCheckout();
  }

  onClickViewCart() {
    this.props.cartMenuHide();

    this.props.history.push("/store/cart/");
  }
 
  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      this.handleResize();
    }
  }

  handleResize() {
    if (this.sidebar && this.sidebar.current) {
      this.sidebar.current.style.height = window.innerHeight  + "px";

      let headerHeight = this.sidebarHeader.current.style.height;
      let footerHeight = this.sidebarFooter && this.sidebarFooter.current ? this.sidebarFooter.current.style.height : 0;

      this.sidebar.current.style.height = (window.innerHeight - headerHeight - footerHeight)  + "px";

      var self = this;
      setTimeout(function(){
        const { show } = self.props;

        if (show) {
          self.sidebarMenu.current.classList.add("show");
        } else {
          self.sidebarMenu.current.classList.remove("show");
        }
      }, 50);      
    }
  } 

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);

    this.handleResize();
  }

  render() {

    const { cartProducts, cartProductsAdded, show } = this.props;

    if (!show)
      return null;

    let isCartEmpty = (typeof cartProducts === "undefined") || (cartProducts.length === 0);

    let addedItemsCount = (typeof cartProductsAdded === "undefined") ? 0 : cartProductsAdded.length;

    return (
      <div className="cart-menu" ref={this.sidebar}>
        <Helmet>
          <body className="no-scroll" />
        </Helmet>

        <div className="cart-menu-sidebar">
          <div className="cart-menu-sidebar left" onClick={() => {this.onClickClose(); this.onAnalyticsSend('Overlay_click')}}></div>
          <div className="cart-menu-sidebar right" ref={this.sidebarMenu}>
            <div className="cart-menu-header" ref={this.sidebarHeader}>
              <div className="title">Your Cart</div>
              <div className="btn-close" onClick={() => {this.onClickClose(); this.onAnalyticsSend('Button_click')}}></div>
            </div>
            <div className="cart-menu-content" ref={this.sidebarContent}>
            { isCartEmpty ?
              <>
              Your cart is empty. <br /> Please <Link to="/store/" onClick={() => {this.onClickClose(); this.onAnalyticsSend('Choose_a_product_click')}}>choose a product and configure a license</Link>.
              </>
              :
              <>
                {addedItemsCount > 0 ? <div className="cart-menu-content-added">{addedItemsCount} items added to cart!</div> : null}

                <CartReview countPickerDisabled={true} checkboxDisabled={true} />                
              </>
              }
            </div>
            { isCartEmpty ? null :            
            <div className="cart-menu-footer" ref={this.sidebarFooter}>
              <Link to="/store/" onClick={() => {this.onClickClose(); this.onAnalyticsSend('Continue shopping click')}}>Continue shopping</Link>
              <div className="actions">
                <CartSubtotal items={cartProducts} />

                <div className="actions-checkout">
                  <div className="link-cart" onClick={() => {this.onClickViewCart(); this.onAnalyticsSend('View_cart_click')}}>View cart</div>
                  <div className="btn-checkout" onClick={() => {this.onClickCheckout(); this.onAnalyticsSend('Checkout_click')}}>Checkout</div>
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CartMenu);
