import React, { Component } from "react";
import './Breadcrumb.scss';

class Breadcrumb extends Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }


  render() {
    let step = this.props.step ? this.props.step : 'configure';
 
    return (
      <div className="order-step">
        <div className={`item ${ (step === 'configure' ? 'active' : '')}`}>1. Configure license</div>
        <div className={`item ${ (step === 'review' ? 'active' : '')}`}>2. Review Cart</div>
        <div className="item">3. Checkout & Buy</div>
      </div>
    );
  }
}

export default Breadcrumb;
