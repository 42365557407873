import React, { Component } from "react";

import ProductsStore from '../../../data/products.js';

class CartCookie extends Component {

  constructor(props) {
    super(props);

    this.cookieName = "sbcart";

    this.updateCookie = this.updateCookie.bind(this);
    this.updateCart = this.updateCart.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cartProducts !== this.props.cartProducts) {
      this.updateCookie();
    }
  }

  componentDidMount() {
    this.updateCart();
  }

  updateCookie() {
    const { cartProducts } = this.props;

    let products = []

    cartProducts.forEach(cartProduct => {
      let count = cartProduct.count ?  cartProduct.count : 1;
      products.push(cartProduct.product.id + ":" + count);
    });

    this.setCookie(this.cookieName, products.join(","));  
  }

  updateCart() {
    let items = [],
      sbcart = this.getCookie(this.cookieName);

    if (sbcart.length != 0) {
      let pairs = sbcart.split(",");

      for(let i=0; i < pairs.length; i++) {
        let pair = pairs[i].split(":");   

        let saleitem = ProductsStore.getSaleItemById(pair[0]);
        if (saleitem) {
          let count = parseInt(pair[1]);
          count = count || 1;
          count = count > 0 ? count : 1;

          items.push({
            product: saleitem,
            count: count
          });
        }

      }
    }

    this.props.cartDeleteProducts();
    this.props.cartAddProducts(items);    
  }

	getCookie(name) {
		var matches = document.cookie.match(new RegExp(
			"(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
		));
		
		return  matches ? decodeURIComponent(matches[1]) : "";
	}
	
	setCookie(name, value) {
		var date = new Date(new Date().getTime() + 2678400000),
			domain = ""; 
			
		document.cookie = name + "=" + value + ";" + "domain=" + domain +";" + " path=/; expires=" + date.toUTCString();
	}
  
  render() {
    return null;
  }
}

export default CartCookie;
