import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cartActions from "../../actions/cartActions";
import CartCheckout from "../../components/common/CartCheckout/CartCheckout.jsx";

const mapStateToProps = (state) => ({
  cartProducts: state.cart.cartProducts,
  isCheckout: state.cart.isCheckout
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(cartActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CartCheckout);
