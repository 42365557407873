import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cartActions from "../../actions/cartActions";
import CartCookie from "../../components/common/CartCookie/CartCookie.jsx";

const mapStateToProps = (state) => ({
  cartProducts: state.cart.cartProducts
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(cartActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CartCookie);
