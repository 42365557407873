const initialState = {
  show: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "CART_MENU_SHOW":
      return {
        ...state,
        show: action.payload
      };

    case "CART_MENU_HIDE":
      return {
        ...state,
        show: action.payload
      };

    default:
      return state;
  }
};
