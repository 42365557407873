import React, { Component } from "react";

import ProductsStore from '../../../data/products.js';
import priceHelper from '../../../utils/priceHelper.js';
import { withRouter } from 'react-router';

const InterPrice = (props) => {
  let { value, interPrice, count=1, region } = props;
    return priceHelper.resolvePrice(value, interPrice, count, region)
}
export default InterPrice;
