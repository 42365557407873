import React, { Component } from "react";
import './Header.scss';

import { Link } from "react-router-dom"; 
import ReactGA from 'react-ga';

import CartIcon from '../../../containers/header/CartIcon.js';
import imgLogo from '../../../assets/SmartBear-Logo.svg';
import ContactChat from '../ContactChat/ContactChat.jsx';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  onAnalyticsSend(value) {
    ReactGA.event({
      category: "StoreComponent",
      action: "Header",
      label: value
    }, ['gadev']);
  }

  render() {

    return (
      <div className="store-header"> 
        <div className="store-header-wrap"> 
          <div className="logo">
            <Link to="/store/" onClick={() => this.onAnalyticsSend('GoToHome')}>
              <img src={imgLogo} />
            </Link>
          </div>
          <div className="cart">
            <CartIcon></CartIcon>
          </div>
          <div className="contact">
            <ContactChat></ContactChat>
          </div>
      </div>
      </div>
    );
  }
}

export default Header;
