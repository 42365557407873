import React, { Component } from "react";
import "./CartCheckout.scss";

import Helmet from "react-helmet";

import Loader from '../Loader/Loader.jsx';

class CartCheckout extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    }

    this.checkoutUrl = "https://shop.smartbear.com/758/";
    this.checkoutCount = 0;

    this.checkout = this.checkout.bind(this);
    this.updateRemoteCart = this.updateRemoteCart.bind(this);
    this.resetRemoteCart = this.resetRemoteCart.bind(this);    
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isCheckout !== this.props.isCheckout) {
      this.setState({
        isLoading: true
      }, () => this.updateRemoteCart());
    }
  }

	resetRemoteCart(callback) {
		var script = document.createElement("iframe");
		script.src = this.checkoutUrl + "?scope=checkout&cartreset=true&t=" + new Date().getTime();
		
		if (typeof(callback) !== "undefined") {
			script.onreadystatechange = function () {
        if (this.readyState == 'complete' || this.readyState == 'loaded') {
          callback();
        } 
      };
			script.onload = callback;
		}

		this.checkoutCount = 0;
			
		(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);		
	}
	
	updateRemoteCart() {
		this.resetRemoteCart(() => this.checkout());
	}
	
	checkout() {
		const { cartProducts } = this.props;

    if (this.checkoutCount < cartProducts.length) {
      let cartProduct = cartProducts[this.checkoutCount];

      this.checkoutCount++;

      if (cartProduct.product.url.length === 0) {
        this.checkout();
        return;
      }

      let count = cartProduct.count ? cartProduct.count : 1;

      let query = "?t=" + new Date().getTime();
      query += "&quantity=" + count;
          
      var script = document.createElement("iframe");
			script.src = this.checkoutUrl + cartProduct.product.url + query;
			
			script.onreadystatechange = function () {
				if (this.readyState == 'complete' || this.readyState == 'loaded') {
					this.checkout();
				}
			};

			script.onload = this.checkout;

		  (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
    } else {
     
     /* this.setState({
        isLoading: false
      }, () => {*/
        this.props.cartDeleteProducts();

        let urlDefaultLanguageCurrentcy = '';
        if (window.location.search.indexOf("cb=default") !== -1)
          urlDefaultLanguageCurrentcy = '&languages=en&currency=USD';

        window.location = this.checkoutUrl + "?scope=checkout&cfg=smartbear2021" + urlDefaultLanguageCurrentcy;        
     /* });*/
    }
	}  

  render() {
    const { isLoading } = this.state;    

    return (
      <>
        { isLoading 
        ? 
        <Loader/>
        :  
        null }
      </>
    )
  }
}

export default CartCheckout;
