import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cartActions from "../actions/cartActions";
import CartReviewPage from "../pages/CartReviewPage.jsx";

const mapStateToProps = (state) => ({
  cartProducts: state.cart.cartProducts
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(cartActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CartReviewPage);
