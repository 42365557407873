import React, { Component } from "react";

import ProductsStore from '../../data/products.js';

import ProductTemplate from '../templates/ProductTemplate.jsx';

import SaleItem from '../../components/common/SaleItem/SaleItem.jsx';
import AddonList from '../../components/common/AddonList/AddonList.jsx';

import queryString from "query-string";
import { withRouter } from "react-router-dom";

class TestCompletePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      licenseType: null,
      platformType: null,
      moduleDesktopChecked: false,
      moduleMobileChecked: false,
      moduleWebChecked: false,
      moduleErrorMessage: false,
      // IDs of selected addons
      addonIds: []
    }

    this.handleLicenseChange = this.handleLicenseChange.bind(this);
    this.handlePlatformChange = this.handlePlatformChange.bind(this);    
    this.handleModuleChange = this.handleModuleChange.bind(this);    
    this.handleAddonChange = this.handleAddonChange.bind(this); 

    this.product = ProductsStore.getProductByUrl('testcomplete');
    
  }

  updateSelection() {
    let searchObj = queryString.parse(window.location.search);

    let licenseType=null,
        platformType=null,
        moduleDesktopChecked=false,
        moduleMobileChecked=false,
        moduleWebChecked=false,
        moduleErrorMessage=false,
        addonIds=[];

    if (searchObj.licenseType) {
      switch(searchObj.licenseType) {
        case 'floating':
        case 'nodeLocked':
          licenseType = searchObj.licenseType;
      }    
    }

    if (licenseType && searchObj.platformType) {
      switch(searchObj.platformType) {
        case 'base':
        case 'pro':
          platformType = searchObj.platformType;
      }    
    }

    if (licenseType && platformType && searchObj.modules) {
      let modules = searchObj.modules.split(",");
      if (modules.length) {
        moduleDesktopChecked = modules.includes("desktop");
        moduleMobileChecked = modules.includes("mobile");
        moduleWebChecked = modules.includes("web");
      }   
    }

    if (searchObj.items) {
      addonIds = searchObj.items.split(",");
    }

    this.setState({
      licenseType: licenseType,
      platformType: platformType,
      moduleDesktopChecked: moduleDesktopChecked,
      moduleMobileChecked: moduleMobileChecked,
      moduleWebChecked: moduleWebChecked,
      addonIds: addonIds
    }, () => this.updateCart());
  }

  componentDidMount(){
    this.updateSelection();
  }

  handleLicenseChange(e) {
    this.setState({
      licenseType: e.target.value
    }, () => this.updateCart());    
  }
 
  handlePlatformChange(e) {
    const isPro = e.target.value === 'pro';

    this.setState({
      platformType: e.target.value,
      moduleDesktopChecked: true,
      moduleMobileChecked: isPro,
      moduleWebChecked: isPro
    }, () => this.updateCart()); 

  }

  handleModuleChange(name, value, checked) {
    const { moduleDesktopChecked, moduleMobileChecked, moduleWebChecked } = this.state;
    let cnt = 0;
    if (moduleDesktopChecked) cnt++;
    if (moduleMobileChecked) cnt++;
    if (moduleWebChecked) cnt++;

    if (cnt === 1 && !checked) {
      this.setState({
        moduleErrorMessage: true
      });
    } 
      switch(name) {
        case 'desktop':
          this.setState({ moduleDesktopChecked: checked }, () => this.updateCart());
          break;
        case 'mobile':
          this.setState({ moduleMobileChecked: checked }, () => this.updateCart());
          break;
        case 'web':
          this.setState({ moduleWebChecked: checked }, () => this.updateCart());
          break;                  
      }
  }

  handleAddonChange(name, value, checked) {

    let addonIds = [...this.state.addonIds ];

    let exists = false;

    addonIds.forEach((item, idx) => {
      if (item === name) {
        exists = true;
      }
    });

    if (checked && !exists) {
      addonIds.push(name);
    } else if (!checked && exists) {
      addonIds = addonIds.filter(item => item !== name);      
    }

    this.setState({ 
      addonIds: addonIds 
    }, () => this.updateCart());
  }

  updateCart() {
    const {licenseType, platformType, moduleDesktopChecked, moduleMobileChecked, moduleWebChecked, addonIds } = this.state;

    let queryParams = [];
    let id = `testcomplete-${licenseType}-`;
    let previewSaleItems = [];
    let saleitems = [];
    switch(licenseType) {
      case 'floating':
        saleitems = this.product.saleitems.floating;
        queryParams.push("licenseType=" + licenseType);
        break;

      case 'nodeLocked':
        saleitems = this.product.saleitems.nodeLocked;
        queryParams.push("licenseType=" + licenseType);
        break;       
    }
    
    switch(platformType) {
      case 'pro':
        id = id + `pro`;

        previewSaleItems = saleitems.filter(s => s.id === id);


        //Legacy version of 
        // let addonTE = ProductsStore.getSaleItemById('testcomplete-addon-te');
        // if (addonTE) {
        //   previewSaleItems.push(addonTE);
          
        // }

        queryParams.push("platformType=pro");
        break;

      case 'base':
        let ids = [];

        if (moduleDesktopChecked) ids.push("desktop");
        if (moduleMobileChecked) ids.push("mobile");
        if (moduleWebChecked) ids.push("web");

        id = id + ids.join('-');

        previewSaleItems = saleitems.filter(s => s.id === id);

        queryParams.push("platformType=base");
        if (ids.length) {
          queryParams.push("modules=" + ids.join(","));
        }
        break;
    }

    // process addons
    let selectedItems = [];
    addonIds.forEach(item => {
      let addon = ProductsStore.getSaleItemById(item);
      if (addon) {
        previewSaleItems.push(addon);
        selectedItems.push(addon.id);
      }
    });

    if (selectedItems.length) {
      queryParams.push("items=" + selectedItems.join(","));
    }

    this.props.cartPreviewAddProducts(previewSaleItems);

    if (queryParams.length) {

      if (window.location.search.indexOf("cb=default") !== -1)
        queryParams.push("cb=default");

      let url = window.location.pathname.replace(process.env.PUBLIC_URL, "");
      this.props.history.push(url + '?' + queryParams.join("&"));
    }
  }

  render() {
    const { licenseType , platformType, moduleDesktopChecked, moduleMobileChecked, moduleWebChecked, addonIds } = this.state;

    const platformDisabled = licenseType === null;
    const moduleDisabled = (licenseType === null) ? true : ((platformType === null) ? true : ((platformType === 'pro') ? true : false));    

    let moduleDesktopPrice = null;
    let moduleMobilePrice = null;
    let moduleWebPrice = null;

    if (platformType === 'base') {
      let modules = ['desktop', 'mobile', 'web'];

      modules.forEach(module => {
        let id = 'testcomplete-' + licenseType + '-' + module;
        let saletime = ProductsStore.getSaleItemById(id);
        if (saletime) {
          switch(module) {
            case 'desktop':
              moduleDesktopPrice = saletime.price;
              break;
            case 'mobile':
              moduleMobilePrice = saletime.price;
              break;
            case 'web':
              moduleWebPrice = saletime.price;
              break;                              
          }
        }        
      });
    }

    let addonOptions = [];
    // For TC Pro TestExecute addon must be checked by default
    if ((platformType === 'pro')) {
      addonOptions.push({
        id: "testcomplete-addon-te",
        disabled: true,
        checked: true
      },{
        id: "testcomplete-addon-device-cloud-iq",
        disabled: true,
        checked: true
        });
    }

    return (
      <ProductTemplate product={this.product}>
      <div className="page-license">
        <div className="page-license-option">
          <div className="page-license-option-title">License type 
            <div className="page-license-option-info" data-html={true} data-tip="<b>Fixed:</b> Single user test on physical machines only<br />
<b>Floating:</b> Multiple users test on physical and virtual machines"></div>
          </div>
          <input type="radio" 
            id="licenseNodeLocked" 
            name="license" 
            value="nodeLocked" 
            checked={licenseType === "nodeLocked"}
            onChange={this.handleLicenseChange} />
          <label htmlFor="licenseNodeLocked">Fixed</label>

          <input type="radio" 
            id="licenseFloat" 
            name="license" 
            value="floating" 
            checked={licenseType === "floating"}
            onChange={this.handleLicenseChange} />
          <label htmlFor="licenseFloat">Floating</label>
        </div>

        <div className={`page-license-option ${ platformDisabled ? "disabled" : ""}`}>
          <div className="page-license-option-title">Platform type</div>

          <div className="page-license-option-select">
            <div className="page-license-option-radio">
              <input type="radio" 
                id="platformBase" 
                name="platform" 
                value="base" 
                checked={platformType === "base"}
                disabled={platformDisabled} 
                onChange={this.handlePlatformChange} />
              <label htmlFor="platformBase">TestComplete Base</label>
            </div>
            <div className="page-license-option-radio">
              <input type="radio" 
                id="platformPro" 
                name="platform" 
                value="pro" 
                checked={platformType === "pro"}
                disabled={platformDisabled} 
                onChange={this.handlePlatformChange} />
              <label htmlFor="platformPro">TestComplete Pro</label> 
            </div>     
          </div>     
        </div>

        <div className={`page-license-option ${ moduleDisabled ? "disabled" : ""}`}>
          <div className="page-license-option-title">Select modules</div>

          <SaleItem name="desktop" 
            price={moduleDesktopPrice}
            disabled={moduleDisabled}
            checked={moduleDesktopChecked}
            title="Desktop"
            information="Automate UI testing for desktop applications across a wide range of tools and controls, including .Net, Windows 10, and Java."
            ico="si-ico-desktop"
            onClick={this.handleModuleChange} />

          <SaleItem name="mobile" 
            price={moduleMobilePrice}
            disabled={moduleDisabled}
            checked={moduleMobileChecked}
            title="Mobile"
            information="Create and automate functional UI tests on physical or virtual iOS and Android devices. No need to jailbreak your phone."
            ico="si-ico-mobile"
            onClick={this.handleModuleChange} />

          <SaleItem name="web" 
            price={moduleWebPrice}
            disabled={moduleDisabled}
            checked={moduleWebChecked}
            title="Web"
            information="Create reusable cross-browser tests for over 1,500 real browsers, operating systems, devices and resolution configurations."
            ico="si-ico-web"
            onClick={this.handleModuleChange} />
        </div>
      </div>
      <AddonList 
          addons={this.product.addons} 
          options={addonOptions} 
          selectedIds={addonIds}
          handleAddonChange={this.handleAddonChange} />      
      </ProductTemplate>
    );
  }
}

export default withRouter(TestCompletePage);
