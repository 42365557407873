import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './reducers/rootReducer';

let middleware = [];

if(process.env.NODE_ENV === 'development'){
    middleware = [...middleware, thunk, logger];
}else{
    middleware = [...middleware, thunk];
}

export default () =>  {
    return createStore (rootReducer, 
        applyMiddleware(...middleware));
}
