import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as productActions from "../../actions/productActions";
import CartSubtotal from '../../components/common/CartSubtotal/CartSubtotal.jsx';

const mapStateToProps = (state) => ({
    interPrice: state.interPrice.interPrice,
    region: state.interPrice.region
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({...productActions}, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CartSubtotal);
