const initialState = {
  isReady: false,
  products: null,
  addons: null,
  activeIndex: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_ADDONS":
      return {
        ...state,
        addons: action.payload,
        //isReady: true
      };
    case "SET_PRODUCTS":
      return {
        ...state,
        products: action.payload,
        isReady: true
      };
    case "SET_FILT_AREA":
      return {
        ...state,
        activeIndex: action.payload
      };
    default:
      return state;
  }
};
