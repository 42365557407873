import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import filter from "lodash/includes";
import orderBy from "lodash/orderBy";

import * as productActions from "../../actions/productActions";
import * as filterActions from "../../actions/filterActions";
import * as cartActions from "../../actions/cartActions";

import HomePage from "../../pages/HomePage.jsx";

import ProductsStore from "../../data/products";

const sortByProducts = (products, filterBy) => {

  products = orderBy(orderBy(products, `Name`, "asc"), "Rank", "desc");

  if (filterBy !== '' && products !== []) {
    return products.filter(prod => {
      return prod.Name.toLowerCase().includes(filterBy.toLowerCase());
    }) 
  }
  else if (filterBy === '') {
    return products;
  }
};

const sortByAddons = (addons, filterBy) => {
  if (filterBy !== '' && addons !== []) {
    return addons.filter(addon => {
      return addon.productName.toLowerCase().includes(filterBy.toLowerCase());
    }) 
  }
  else if (filterBy === '') {
    return addons;
  }
}


let productsChanged = [];
const filterProducts = (products, selectedItems) => {
  if (selectedItems.length > 0) {
     productsChanged = products;
    for (let i = 0; i < selectedItems.length; i++) {

      if (!!selectedItems[i].Solutions) {
        productsChanged = productsChanged.filter(o => {
          if (o.Solutions !== null){
            return o.Solutions.toLowerCase().includes(selectedItems[i].Solutions.toLowerCase())}
          else return null
        }
        );
      }

      if (!!selectedItems[i].Platforms) {
        productsChanged = productsChanged.filter(o => {
          if(o.Platforms !== null) {
            return o.Platforms.toLowerCase().includes(selectedItems[i].Platforms.toLowerCase())}
          else return null}
        );
      }

      if (!!selectedItems[i].MarketPlaces) {
        productsChanged = productsChanged.filter(o => {
          if(o.MarketPlaces !== null) {
            return o.MarketPlaces.toLowerCase().includes(selectedItems[i].MarketPlaces.toLowerCase())}
          else return null}
        );
      }  

      if (!!selectedItems[i].ProductAddons) {
        productsChanged = productsChanged.filter(o => {
          if (o.ProductAddons !== null) {
            return o.Name.toLowerCase().includes(selectedItems[i].ProductAddons.toLowerCase())}
          else return null}
        )
      }
    }

    
    
    // Query product filter neews to be last 
    let queryProducts = [];
    for (let i = 0; i < selectedItems.length; i++) {
      if (!!selectedItems[i].QueryProducts) {

        for (let j = 0; j < productsChanged.length; j++) {
          if (selectedItems[i].QueryProducts.trim().toLowerCase() === productsChanged[j].Name.toLowerCase()) {
            queryProducts.push(products[j]);
          }
        }
      }  
    }

    if (queryProducts.length) {
      productsChanged = [...queryProducts];
    }

    return productsChanged;
  } else if (selectedItems.length === 0) 
  {
    return products;
  }
};

const filterAddons = (addons, selectedItems) => { 
  if (selectedItems.length > 0) {
    let addonsChanged = addons;
    for (let i = 0; i < selectedItems.length; i++) {
      if (!!selectedItems[i].ProductAddons) {
        addonsChanged = addonsChanged.filter(o => {
          if (o.productName !== null) {
            return o.productName.toLowerCase().includes(selectedItems[i].ProductAddons.toLowerCase())
          }
          else return null
        })
      }
      if (productsChanged.length > 0) {
        let addons = [];
        addonsChanged = addonsChanged.filter(o => {
          productsChanged.forEach(product => {
            if (product.Name.toLowerCase() === o.productName.toLowerCase()) {
              addons = addons.concat(o);
            }
          })
        })
        addonsChanged = addons;
      }
    }
    return addonsChanged;
  }  
  else if ( selectedItems.length === 0 ) {
    return addons;
  }
}

const filteringProducts = (products, filterBy, selectedItems) => {
  const filterProductsResult = filterProducts(products, selectedItems);
  return sortByProducts(filterProductsResult, filterBy);
};

const filteringAddons = (addons, filterBy, selectedItems) => {
  const filterAddonsResult = filterAddons(addons, selectedItems);
  return sortByAddons(filterAddonsResult, filterBy);
};

const mapStateToProps = state => {

  const { products, solutions, marketPlaces,
    platforms, filter, mobileFilter } = state;
  const mapState = {
    // allProducts is needed for recommended items
    allProducts: products.products, 
    products:
      products.products &&
      filteringProducts(
        products.products,
        filter.filterBy,
        filter.selectedItems
      ),
    addons: products.addons && 
      filteringAddons(
        products.addons, 
        filter.filterBy,
        filter.selectedItems
        ),
    solutions: solutions,
    marketPlaces: marketPlaces,
    platforms: platforms,
    isReady: products.isReady,
    area: products.activeIndex,
    show: mobileFilter.show,
    filter
  };

  return mapState;
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ 
    ...productActions,
    ...filterActions,
    ...cartActions,
  }, 
  dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
