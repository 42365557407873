const initialState = {
  cartPreviewProducts: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "CARTPREVIEW_ADD_PRODUCTS":
      return {
        ...state,
        cartPreviewProducts: action.payload
      };
    case "CARTPREVIEW_DELETE_PRODUCTS":
      return {
        ...state,
        cartPreviewProducts: action.payload
      };      
    default:
      return state;
  }
};
