import React, { Component } from "react";

import './CartSubtotal.scss';

import priceHelper from '../../../utils/priceHelper.js';

class CartSubtotal extends Component {
  render() {
    let { items, interPrice, region } = this.props;

    if (typeof items === "undefined")
      items = [];

    const totalPrice = priceHelper.getTotalPrice(items, interPrice, region);

    return (
      <div className="cart-subtotal">
        <div className="cart-subtotal-info"> 
          <div className="cart-subtotal-info-title">Subtotal</div>
          <div className="cart-subtotal-info-dots"></div>
          <div className="cart-subtotal-info-price">{totalPrice}</div>
        </div>
        <div className="cart-subtotal-desc"> 
          Sales tax may be applied based on your address information.
        </div>
      </div>
    );
  }
}

export default CartSubtotal;
