
import ProductStore from "../data/products.js";

class priceHelper {

    constructor() {        

    }

    getCurrency(price) {
        var strEuro = "&euro;",
            index = price.indexOf(strEuro);
    
        if (index !== -1) {
            return "€";
        }
    
        return price.substring(0, 1);
      }
    
      prettyPrice(price, currency) {
    
        var displayPrice = price.toString();
        if (displayPrice.length >= 4) {
            displayPrice = displayPrice.substr(0, displayPrice.length - 3) + "," + displayPrice.substr(displayPrice.length - 3);
        }
        if(displayPrice == "NaN") {
          return "Contact Us";
        } else if(displayPrice == "0"){
          return "Free";
        }else {
          return currency + displayPrice;
        }
      }
    
      getItemPrice(price) { 
        //check if price contains a dot
        var currentPrice = price,
          indexDot = price.indexOf(".");
      
        if (indexDot !== -1)
          currentPrice = currentPrice.substring(0, indexDot);
      
        price = parseInt(currentPrice.replace(/\D/g, ''), 10);
        return price;
      }
    
      resolvePrice(value, interPrice, count, region) {
        if (value && region) {
          if (value.indexOf("{") === -1)
              return value;
      
               value = value.replace("{", "").replace("}", "").replace("|", "").replace("interprice", "").replace("~", "").replace("~", "").replace(" ", "");
          }

          let price = '';
          let currency = '';
          region = region || "Price_USD";

          if (interPrice) {
            interPrice.forEach(item => {
                if (item.ProductCode === value) {
                    currency = this.getCurrency(item[region]);
                    price = item[region].replace("&euro;" , '').replace(currency, '').replace(',', '').replace('.', '') * count;
                }
            });
          }
          return this.prettyPrice(price, currency);
        }



      getTotalPrice(items, interPrice, region) {
    
        let cartTotalPrice = 0;
        let currency = '';
    
        items.forEach(item => {
          if (ProductStore.isTestComplete(item.product.id) && (item.product.id.indexOf("-pro") === -1)) {
            let saleitems = ProductStore.getTestCompleteSaleitems(item.product.id);

            saleitems.forEach(saleitem => {
              let price = saleitem.price;
              let itemCount = item.count || 1;
  
              if (price.length) {
                price = this.resolvePrice(price, interPrice, itemCount, region);
                currency = this.getCurrency(price);                
        
                cartTotalPrice += this.getItemPrice(price);
              }
            });

          } else {
            let price = item.product.price;
            let itemCount = item.count || 1;

            if (price.length) {
              price = this.resolvePrice(price, interPrice, itemCount, region);
              currency = this.getCurrency(price);                
      
              cartTotalPrice += this.getItemPrice(price);
            }
          }
        });
    
        var displayPrice = cartTotalPrice.toString();
        return this.prettyPrice(displayPrice, currency);
      }
}

export default new priceHelper();