import icon from '../assets/ReadyAPI-Logo.svg';

const ReadyAPIConfig = {
    name: "ReadyAPI",
    url: "readyapi",
    icoClassName: "ra",
    ico: icon,

    saleitems: {
        performance: {
            floating: [{
                id: "readyapi-performance-small-floating",
                title: "ReadyAPI Performance - Small - 250 VU/TPS",
                licenseType: "Floating User License",
                description: "",
                price: "{~interprice|RALS-1Y-SBN-FL~}",
                url: "purl-RLUI1FL"
            }, {
                id: "readyapi-performance-medium-floating",
                title: "ReadyAPI Performance - Medium - 1000 VU/TPS",
                licenseType: "Floating User License",
                description: "",
                price: "{~interprice|RALM-1Y-SBN-FL~}",
                url: "purl-RLUIM1FL"
            }, {
                id: "readyapi-performance-unlimited-floating",
                title: "ReadyAPI Performance - Unlimited VU/TPS",
                licenseType: "Floating User License",
                description: "",
                price: "{~interprice|RALU-1Y-SBN-FL~}",
                url: "purl-RLUIU1FL"
            }],
            nodeLocked: [{
                id: "readyapi-performance-small-fixed",
                title: "ReadyAPI Performance - Small -  250 VU/TPS",
                licenseType: "Fixed License - 1 Year Subscription",
                description: "",
                price: "{~interprice|RALS-1Y-SBN-NL~}",
                url: "purl-RLUI1NL"
            }, {
                id: "readyapi-performance-medium-fixed",
                title: "ReadyAPI Performance - Medium - 1000 VU/TPS",
                licenseType: "Fixed License - 1 Year Subscription",
                description: "",
                price: "{~interprice|RALM-1Y-SBN-NL~}",
                url: "purl-RLUIM1NL"
            }, {
                id: "readyapi-performance-unlimited-fixed",
                title: "ReadyAPI Performance - Unlimited VU/TPS",
                licenseType: "Fixed License - 1 Year Subscription",
                description: "",
                price: "{~interprice|RALU-1Y-SBN-NL~}",
                url: "purl-RLUIU1NL"
            }]
        },
        test: {
            floating: [{
                id: "readyapi-test-1-year-floating",
                title: "ReadyAPI Test - 1-Year License",
                licenseType: "Floating User License",
                description: "",
                price: "{~interprice|RAFU-1Y-SBN-FL~}",
                url: "purl-RSUI1FL"
            }],
            nodeLocked:[{
                id: "readyapi-test-1-year-fixed",
                title: "ReadyAPI Test - 1-Year License",
                licenseType: "Fixed User License -  1 Year Subscription",
                description: "",
                price: "{~interprice|RAFU-1Y-SBN-NL~}",
                url: "purl-RSUI1NL"
            }, {
                id: "readyapi-test-2-year-fixed",
                title: "ReadyAPI Test - 2-Year License",
                licenseType: "Fixed User License -  2 Year Subscription",
                description: "",
                price: "{~interprice|RAFU-2Y-SBN-NL~}",
                url: "purl-RSUI2NL"
            }, {
                id: "readyapi-test-3-year-fixed",
                title: "ReadyAPI Test - 3-Year License",
                licenseType: "Fixed User License -  3 Year Subscription",
                description: "",
                price: "{~interprice|RAFU-3Y-SBN-NL~}",
                url: "purl-RSUI3NL"
            }]
        },
        virtualization: {
            floating: [{
                id: "readyapi-virtualization-1-year-floating",
                title: "ReadyAPI Virtualization - 1-Year License",
                licenseType: "Floating User License",
                description: "",
                price: "{~interprice|RAVI-1Y-SBN-FL~}",
                url: "purl-RSV1FL"
            }],
            nodeLocked: [{
                id: "readyapi-virtualization-1-year-fixed",
                title: "ReadyAPI Virtualization - 1-Year License",
                licenseType: "Fixed User License -  1 Year Subscription",
                description: "",
                price: "{~interprice|RAVI-1Y-SBN-NL~}",
                url: "purl-RSV1NL"
            }]
        }
    },
}

export default ReadyAPIConfig;