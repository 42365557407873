import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as filterActions from "../../actions/filterActions";
import Filter from '../../components/home/Filter.jsx'

const mapStateToProps = ({ filter }) => ({
  filterBy: filter.filterBy,
  selectedItems: filter.selectedItems
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(filterActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
