import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cartPreviewActions from "../actions/cartPreviewActions";
import CollaboratorPage from "../pages/products/CollaboratorPage.jsx";

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(cartPreviewActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CollaboratorPage);
