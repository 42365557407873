import React, { Component } from "react";
import InterPrice from '../../../containers/common/InterPrice/InterPrice';

import './SaleItem.scss';

class SaleItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.checked ? this.props.checked : false,
      value: this.props.value ? this.props.value : null,
      name: this.props.name
    }

    this.handleClick = this.handleClick.bind(this);
    this.handleClickCallback = this.handleClickCallback.bind(this); 
  }

  handleClickCallback(checked) {
    const { name, value } = this.state;

    if (this.props.onClick) {
      this.props.onClick(name, value, checked);
    }    
  }

  handleClick() {
    const { checked } = this.state;

    if (!this.props.disabled) {
      this.setState({
        checked: !checked
      }, () => this.handleClickCallback(!checked));
    }
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.checked !== this.props.checked)) {
      this.setState({
        checked: this.props.checked
      });
    }
  }

  render() {
    const cssChecked = this.state.checked ? "si-active" : "";
    const cssClassName = this.props.className ? this.props.className : "";
    const cssDisabled = this.props.disabled ? "disabled" : "";

    return (
      <div className={`si ${cssClassName} ${cssChecked} ${cssDisabled}`} onClick={this.handleClick}>
        <div className="si-checkbox">
          <input type="checkbox" 
            name={this.props.name} 
            checked={this.state.checked} 
            disabled={this.props.disabled} 
            onChange={this.handleClick} />
        </div>
        {
          this.props.ico ? <div className={`si-ico ${this.props.ico}`}></div> : null
        }
        <div className="si-title">{this.props.title}{this.props.price ? <div className='saleprice'><InterPrice value={this.props.price}/></div> : null}</div>
        {
          this.props.information ? <div className="si-info" data-tip={this.props.information}></div> : null
        }        
        
      </div>
    );
  }
}

export default SaleItem;
