import React, { Component } from "react";
import './AddonList.scss';

import SaleItem from '../SaleItem/SaleItem.jsx';

class AddonList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    }

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { isOpen } = this.state;

    this.setState({
      isOpen: !isOpen
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedIds !== this.props.selectedIds) {
      if (this.props.selectedIds.length) { 
        this.setState({
          isOpen: true
        });
      }
    } else if (prevProps.options !== this.props.options) {
      if (this.props.options.length) { 
        this.setState({
          isOpen: true
        });
      }
    }
  }


  render() {
    const { isOpen } = this.state;

    let selectedIds = this.props.selectedIds ? this.props.selectedIds : [];
    let addons = this.props.addons ? this.props.addons : [];
    let options = this.props.options ? this.props.options : [];
    let handleAddonChange = this.props.handleAddonChange ? this.props.handleAddonChange : null;

    return (
      <div className="addons">
        <div className={`addons-header ${isOpen ? "open" : ""}`} onClick={this.onClick}>
          <div className="addons-header-title">Add-ons</div>
          <div className="addons-header-desc">Certified Training, Additional support & applicaiton add-ons</div>
        </div>
        <div className={`addons-content ${isOpen ? "" : "open"}`}>

        { addons.map((saleitem, i) => {

          let disabled = false;
          let checked = false;

          options.forEach(option => {
            if (option.id === saleitem.id) {
              disabled = option.disabled;
              checked = option.checked;
            }
          });

          if (selectedIds.includes(saleitem.id)) {
            checked = true;
          }

          return (
            <SaleItem key={i}
              name={saleitem.id}
              title={saleitem.title}
              price={saleitem.price}
              information={saleitem.description}
              disabled={disabled}
              checked={checked}
              className="si-addon"
              onClick={handleAddonChange} />
          )
        })}

        </div>
      </div>
    );
  }
}

export default AddonList;
