import React from 'react';
import { Card } from 'semantic-ui-react';

import Price from '../../containers/common/InterPrice/InterPrice.js';

import { Link } from "react-router-dom";

import "./Card.scss";

export default ({product, products, analyticsClick}) => (
    <Card /*className={product.ProductFamilyCSSClass}*/>
    <Card.Content className="product-information">
      {!product.LinkToPricing ? 
      (<Link to={`/store/${product.Url}/`} className="prod-header" onClick={()=> {
         analyticsClick(`Card`, product.Name + '_HeaderClick');
         }}>
        <div className={`prod-icon ${product.IconCSSClass}`}></div>
        <div className="prod-name">{product.Name}</div>
      </Link>)
      : 
      (<a href={product.LinkToPricing} className="prod-header" target="_blank" rel="noopener noreferrer" onClick={()=> {
        analyticsClick(`Card`, product.Name + '_HeaderClick');
        }}>
        <div className={`prod-icon ${product.IconCSSClass}`}></div>
        <div className="prod-name">{product.Name}</div>
      </a>
      )
      }
      <div className="prod-desc">
        {product.Description}
      </div>
      <div className="price-place">
        <div className="price-starting-at">starting at</div>
        <div className="prod-price" data-testid={`${product.Name.replace(/\s/g, '')}-price`}>
           <Price value= {product.Price} count={1} />
         </div>
      </div>
      <div className="prod-actions">
        <div className="product-action-link">
            {!product.LinkToPricing ? <Link to={`/store/${product.Url}/`} className="prod-link-buy" onClick={()=>analyticsClick(`Card`, product.Name + '_Buy')}>
              Buy now <i className="fal fa-long-arrow-right"></i>
            </Link>
            : 
            <a href={product.LinkToPricing} className="prod-link-buy" target="_blank" rel="noopener noreferrer" onClick={()=>analyticsClick(`Card`, product.Name + '_Buy')}>
              Buy now <i className="fal fa-long-arrow-right"></i>
            </a>}
        </div>
        <div className="product-action-link">
        <a href={product.LearnMoreLink} className="prod-link" onClick={()=>analyticsClick(`Card`, product.Name + '_LearnMore')}>Learn more <i className="fal fa-long-arrow-right"></i></a>
        </div>
      </div>
    </Card.Content>
  </Card>
)