import React, { Component } from "react";

import ProductsStore from '../../data/products.js';

import ProductTemplate from '../templates/ProductTemplate.jsx';
import Price from '../../components/common/InterPrice/InterPrice.jsx';

import SaleItem from '../../components/common/SaleItem/SaleItem.jsx';

import queryString from "query-string";
import { withRouter } from "react-router-dom";

class TestExecutePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      moduleFloatingChecked: false,
      moduleErrorMessage: false,
    }
 
    this.handleModuleChange = this.handleModuleChange.bind(this);    

    this.product = ProductsStore.getProductByUrl('testexecute');
  }

  updateSelection() {
    let searchObj = queryString.parse(window.location.search);
    let moduleFloatingChecked = false;



      if (searchObj.modules) {
      let modules = searchObj.modules.split(",");
      if (modules.length) {
        moduleFloatingChecked = modules.includes("testexecute-floating");
      }   
    }

    this.setState({
      moduleFloatingChecked: moduleFloatingChecked,
    }, () => this.updateCart());
  }

  componentDidMount(){
    this.updateSelection();
  }

  handleModuleChange(name, value, checked) {
    const { moduleFloatingChecked } = this.state;
    let cnt = 0;
    if (moduleFloatingChecked) cnt++;

    if (cnt === 1 && !checked) {
      this.setState({
        moduleErrorMessage: true
      });
    } 
    switch(name) {
      case 'floating':
        this.setState({ moduleFloatingChecked: checked}, () => this.updateCart());
        break;              
    }
  }

  updateCart() {
    const {moduleFloatingChecked} = this.state;

    let previewSaleItems = [];
    let saleitems = [];
    let queryParams = [];

    if (moduleFloatingChecked) saleitems.push(this.product.saleitems.floating[0]);

    previewSaleItems = saleitems

    if (saleitems.length > 0) {
      let Ids = [];
      saleitems.forEach(item => { 
        Ids.push(item.id);
      })
        queryParams.push("modules=" + Ids.join(","))
    }

    if (queryParams.length) {
      let url = window.location.pathname.replace(process.env.PUBLIC_URL, "");
      this.props.history.push(url + '?' + queryParams.join("&"));
    }

    this.props.cartPreviewAddProducts(previewSaleItems);
  }

  render() {
    const { moduleFloatingChecked } = this.state;
    

    return (
      <ProductTemplate product={this.product}>
      <div className="page-license">
        <div className={`page-license-option`}>
          <div className="page-license-option-title">Choose license:</div>

          <SaleItem name="floating" 
            disabled={''}
            checked={moduleFloatingChecked}
            title={this.product.saleitems.floating[0].title}
            price ={this.product.saleitems.floating[0].price}
            information={this.product.saleitems.floating[0].description}
            className="si-addon"
            ico=""
            onClick={this.handleModuleChange} />
        </div>
      </div>  
      </ProductTemplate>
    );
  }
}

export default withRouter(TestExecutePage);
