const initialState = {
  cartProducts: [],
  cartProductsAdded: [],
  isCheckout: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "CART_ADD_PRODUCTS":

      let products = [... state.cartProducts];

      action.payload.forEach(payloadProduct => {

        let product = null;
        products.forEach(cartProduct => {
          if (cartProduct.product.id === payloadProduct.product.id) {
            product = cartProduct;
          }
        });

        if (product) {
          product.count = product.count || 1;
          payloadProduct.count = payloadProduct.count || 1;

          product.count += payloadProduct.count;
        } else {
          product = payloadProduct;

          products.push(product);
        }
        
      });

      return {
        ...state,
        cartProducts: products,
        cartProductsAdded: [... action.payload]
      };

    case "CART_UPDATE_PRODUCTS":
      return {
        ...state,
        cartProducts: action.payload
      };      

    case "CART_DELETE_PRODUCTS":

      let cartProducts = [... state.cartProducts];

      action.payload.forEach(id => {
        cartProducts = cartProducts.filter(cartProduct=> cartProduct.product.id !== id);
      });

      return {
         ...state,
        cartProducts: cartProducts
      }; 

    case "CART_DELETE_ALL_PRODUCTS":
      return {
        ...state,
        cartProducts: []
      };     

    case "CART_CHECKOUT":
      return {
        ...state,
        cartProducts: state.cartProducts,
        isCheckout: action.payload
      }; 

    case "CART_RESET_ADDED_PRODUCTS":
      return {
         ...state,
         cartProductsAdded: action.payload
      };       

    default:
      return state;
  }
};
