import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cartActions from "../../actions/cartActions";
import * as cartPreviewActions from "../../actions/cartPreviewActions";
import CartPreview from "../../components/common/CartPreview/CartPreview.jsx";

const mapStateToProps = (state) => ({
  cartPreviewProducts: state.cartPreview.cartPreviewProducts,
  interPrice: state.interPrice.interPrice
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    ...cartActions, 
    ...cartPreviewActions}, 
    dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CartPreview);
