import React, { Component } from "react";
import Helmet from "react-helmet";

import Header from '../components/header/Header/Header.jsx'
import Footer from '../components/footer/footer'
import sb_404 from '../assets/sb_404.svg';

import './NotFoundPage.scss';

class NotFoundPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Header/>
        <Helmet>
          <body className="error-404" />
        </Helmet>

        <div className="col-12 page-notfound">
          <img src={sb_404} alt="" />
          <h1>We're sorry but the page you were looking for was not found.</h1>
          <p>
            Please use the site navigation above to find the page you are looking for or visit our <a href="//smartbear.com/site-map">sitemap</a>.<br/>
            You can also help us by <a href="//smartbear.com/webformsubmission">reporting the issue here</a>.
          </p>
        </div>
        <Footer/>
      </React.Fragment>
    )
  }
};

export default NotFoundPage;