import TestCompleteConfig from "./TestComplete.js";
import AQTimeConfig from "./AQTimePro.js";
import TestLeftConfig from './TestLeft';
import ReadyAPIConfig from './ReadyAPI.js';
import CollaboratorConfig from './Collaborator.js';
import TestExecuteConfig from './TestExecute.js';

class ProductsStore {

    constructor() {        
        this.products = [TestCompleteConfig, AQTimeConfig, TestLeftConfig, ReadyAPIConfig, CollaboratorConfig, TestExecuteConfig];
        this.products = this.products.sort((a, b) => a.name.localeCompare(b.name));

        this.saleitems = [];
        this.interprices = [];
        this.addons = [];

        this.products.forEach(product => {
            if (product.saleitems) {
                if (Array.isArray(product.saleitems.floating)) {
                    let items = [];

                    product.saleitems.floating.forEach(item => {
                        item = Object.assign(item, {productName: product.name, productIcon: product.icoClassName})
                        items.push(item);
                    });


                    this.saleitems = this.saleitems.concat(items);
                }
                if (Array.isArray(product.saleitems.nodeLocked)) {
                    let items = [];

                    product.saleitems.nodeLocked.forEach(item => {
                        item = Object.assign(item, {productName: product.name, productIcon: product.icoClassName})
                        items.push(item);
                    });

                    this.saleitems = this.saleitems.concat(items);
                }           
                if (product.saleitems.performance && Array.isArray(product.saleitems.performance.floating)) {
                    let items = [];

                    product.saleitems.performance.floating.forEach(item => {
                        item = Object.assign(item, {productName: product.name, productIcon: product.icoClassName})
                        items.push(item);
                    });
                    this.saleitems = this.saleitems.concat(items);
                }    
                if (product.saleitems.performance && Array.isArray(product.saleitems.performance.nodeLocked)) {
                    let items = [];

                    product.saleitems.performance.nodeLocked.forEach(item => {
                        item = Object.assign(item, {productName: product.name, productIcon: product.icoClassName})
                        items.push(item);
                    });
                    this.saleitems = this.saleitems.concat(items);
                }  
                if (product.saleitems.test && Array.isArray(product.saleitems.test.floating)) {
                    let items = [];

                    product.saleitems.test.floating.forEach(item => {
                        item = Object.assign(item, {productName: product.name, productIcon: product.icoClassName})
                        items.push(item);
                    });
                    this.saleitems = this.saleitems.concat(items);
                }    
                if (product.saleitems.test && Array.isArray(product.saleitems.test.nodeLocked)) {
                    let items = [];

                    product.saleitems.test.nodeLocked.forEach(item => {
                        item = Object.assign(item, {productName: product.name, productIcon: product.icoClassName})
                        items.push(item);
                    });
                    this.saleitems = this.saleitems.concat(items);
                }
                if (product.saleitems.virtualization && Array.isArray(product.saleitems.virtualization.floating)) {
                    let items = [];

                    product.saleitems.virtualization.floating.forEach(item => {
                        item = Object.assign(item, {productName: product.name, productIcon: product.icoClassName})
                        items.push(item);
                    });
                    this.saleitems = this.saleitems.concat(items);
                }    
                if (product.saleitems.virtualization && Array.isArray(product.saleitems.virtualization.nodeLocked)) {
                    let items = [];

                    product.saleitems.virtualization.nodeLocked.forEach(item => {
                        item = Object.assign(item, {productName: product.name, productIcon: product.icoClassName})
                        items.push(item);
                    });
                    this.saleitems = this.saleitems.concat(items);
                }
            }
            if (Array.isArray(product.addons)) {
                let items = [];

                product.addons.forEach(item => {
                    item = Object.assign(item, {productName: product.name, productIcon: product.icoClassName})
                    items.push(item);
                });

                this.saleitems = this.saleitems.concat(items);
            }      
            if (Array.isArray(product.addons)) {
                let items = [];

                product.addons.map(item => {
                    item = Object.assign(item, {productName: product.name, productIcon: product.icoClassName})
                    items.push(item);
                });
                
                this.addons = this.addons.concat(items);
            }             
        });

    }

    getProductByUrl(url) {
        let product = null;

        this.products.forEach(item => {
            if (item.url === url) {
                product = item;
            }
        });

        return product;
    }

    getSaleItemById(id) {
        let saleitem = null;

        this.saleitems.forEach(item => {
            if (item.id === id) {
                saleitem = item;
            }
        });

        return saleitem;
    }    

    getProducts() {
        return this.products;
    }

    getSaleItems() {
        return this.saleitems;
    } 
    
    getInterPrices() {
        return this.interprices;
    }

    getAddons() {
        return this.addons;
    }


    isTestComplete(id) {
        return ((id.indexOf("testcomplete-") === 0) && (id.indexOf("testcomplete-addon-") === -1));
    }

    getTestCompleteSaleitems(id) {
        let ids = id.split("-");
        let licenseType = ids.splice(1,1);
        let prefixId = `testcomplete-${licenseType}-`;
        ids = ids.slice(1);

        let items = [];

        let platform = this.saleitems.filter(si => si.id === prefixId + 'platform');    
        if (platform.length) {
          items.push(platform[0]);
        }        

        ids.forEach(id => {
          let item = this.saleitems.filter(si => si.id === prefixId + id);
          if (item.length) {
            items.push(item[0]);            
          }          
        });

        return items;
    }
}

export default new ProductsStore()