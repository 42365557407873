import React, { Component } from "react";
import './PageTemplate.scss';

import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Helmet from "react-helmet";

import CartPreview from '../../containers/common/CartPreview.js';
import Breadcrumb from '../../components/common/Breadcrumb/Breadcrumb.jsx';

import DefaultTemplate from '../../containers/templates/DefaultTemplate.js';

class ProductTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
     
    } 
  }


  render() {
    return (
      <DefaultTemplate>
        <Helmet title = {'Store - ' + this.props.product.name + ' | SmartBear Software'}/>
        <div className="page">
          <div className="page-header">
              <Link to="/store/" className="page-header-back">
                <i className="fal fa-long-arrow-left"></i> Back to products
              </Link>
              <img className="page-header-logo" src={this.props.product.ico} />
              <Breadcrumb step="configure"></Breadcrumb>
          </div>
          <div className="page-content product">
            <div className="page-content-left">
                {this.props.children}  

                <ReactTooltip place="right" className="page-tooltip" arrowColor="lightblue" />
            </div>
            <div className="page-content-right"> 
                <CartPreview></CartPreview>
            </div>  
          </div>                  
        </div>
      </DefaultTemplate>
    );
  }
}

export default ProductTemplate;
