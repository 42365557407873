import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cartActions from "../../actions/cartActions";
import CartIcon from "../../components/header/CartIcon/CartIcon.jsx";

const mapStateToProps = (state) => ({
  cartProducts: state.cart.cartProducts
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(cartActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CartIcon);
