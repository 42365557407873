import React from 'react';
import { Card } from 'semantic-ui-react';

import Price from '../../containers/common/InterPrice/InterPrice.js';

import "./Card.scss";

export default ({addon, cartAddProducts, cartMenuShow, analyticsClick}) => (
    <Card>
    <Card.Content className="product-information">
      <div className="prod-header" onClick={()=> {;
         analyticsClick(`AddonCard`, addon.title + '_HeaderClick');
         }}>
        <div className={`prod-icon ${addon.productIcon}`}></div>
        <div className="prod-name">{addon.productName}</div>
      </div>
      <div className="addon-name">{addon.title}</div>
      {/* <div className="prod-desc">
        {addon.description}
      </div> */}
         <div className='prod-price'>
           <Price value= {addon.price} count={1} />
         </div>
      <div className="prod-actions">
        <div className="product-action-link">
        <div className="prod-link-buy" onClick={()=>(analyticsClick(`Card`, addon.DocumentName + '_AddAddonToCart'), cartAddProducts([{ product: addon}]), cartMenuShow())}><i className="far fa-plus"></i>{'  Add to Cart'}</div>
        </div>
      </div>
    </Card.Content>
  </Card>
)